import '../styles/VentanaCrearNuevaInstancia.css';
import { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';


function VentanaCrearNuevaInstancia(props) {

  const { confirmarCreacion, setConfirmarCreacion, crearInstancia } = props;


  useEffect(() => {
    if(confirmarCreacion != undefined) {
      if(confirmarCreacion) {
        const VentanaCrearNuevaInstancia = document.querySelectorAll('.VentanaCrearNuevaInstanciaContainer');
        if (VentanaCrearNuevaInstancia[0]) VentanaCrearNuevaInstancia[0].style.display = 'block';
      } else {
        const VentanaCrearNuevaInstancia = document.querySelectorAll('.VentanaCrearNuevaInstanciaContainer');
        if (VentanaCrearNuevaInstancia[0]) VentanaCrearNuevaInstancia[0].style.display = 'none';
      }
    }
  
  }, [confirmarCreacion]);


  return (
    <div className="VentanaCrearNuevaInstanciaContainer">
      <div className="VentanaCrearNuevaInstancia">
        <h4>¿Estás seguro?</h4>
        <p className="LetraGris">Si creás una nueva instancia perderás los puntos obtenidos en el nivel actual 
        y el acceso a la información adicional desbloqueada. El nivel pasará a estado 
        "No resuelto" hasta que lo vuelvas a superar.</p>
        <Button onClick={() => {setConfirmarCreacion(false);}} variant="outline-success">Cancelar</Button> &nbsp;&nbsp;&nbsp;
        <Button onClick={() => {setConfirmarCreacion(false); crearInstancia();}} variant="outline-warning">Crear nueva instancia</Button>
      </div>
    </div>
  );

}

export default VentanaCrearNuevaInstancia;