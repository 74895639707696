import React, { useEffect, useRef } from 'react';
import ReCAPTCHA from "react-google-recaptcha";


function RecaptchaForm(props) {

  const { establecerTokenRecaptcha } = props;

  function onChange(token) {
    //try {
    //console.log("Captcha value:", token);
    establecerTokenRecaptcha(token);
    //} catch (error) {
    //}
  }


  useEffect(() => {
    //console.log('Montado.');
      
    // Limpia el efecto al desmontar el componente
    return () => {
      //console.log('Desmontado.');
    };
  }, []);

  
  /*
  useEffect(() => {
    return () => {
      console.log("Desmontado");
      // Limpia el componente ReCAPTCHA al desmontarlo
      //if (recaptchaRef.current) {
      //  console.log("Desmontado");
      //  recaptchaRef.current.reset();
      //}
    };
  }, []);
  */

  return (
    <ReCAPTCHA
      sitekey="6Lc5UyIoAAAAADvVWswkpHylYg_vEqQ5LUO6-EIV" // Clave de sitio reCAPTCHA
      onChange={onChange}
    />
  );
}

export default RecaptchaForm;