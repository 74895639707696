import '../styles/App.css';
import '../styles/VentanaInstalarMetaMask.css';
import Button from 'react-bootstrap/Button';
import Footer from './Footer.js';



function VentanaInstalarMetaMask(props) {

  return (
    <div className="VentanaInstalarMetaMask">

      <header className="Header">
        <h1 className="glitchText" datatext={"HACONTI"}></h1>
        <h3 className="LetraVerde"><b>Hackeá Al Contrato No Tan Inteligente</b></h3>
      </header>

      <div className="BodyInstalarMetaMask">

        <div className="TextoVentanaInstalarMetaMask">
          <p className="LetraGris">
          ¡Bienvenido a HACONTI, la plataforma de desafíos de ciberseguridad en Smart Contracts
          diseñada para quienes se inician en el campo! ¡Comenzá sin conocimientos previos y
          convertite en un experto en Solidity y en seguridad en contratos inteligentes!
          <br/><br/>
          {/*¡Descubrí y aprendé a explotar las vulnerabilidades en contratos inteligentes, que en <br/>
          el mundo real condujeron al robo de millones de dólares en criptomonedas!<br/>
          <br/>*/}
          Para jugar es necesario instalar la extensión de navegador MetaMask, seguir los
          pasos de la extensión para crear un nuevo monedero, y luego conectarte a la red de
          pruebas de Sepolia con tu nueva cuenta de Ethereum del monedero creado:</p>
        </div>

        <div className="BotonVentanaInstalarMetaMask">
          <a href="https://metamask.io/" target="_blank" rel="noopener noreferrer">
            <Button variant="outline-success">
              Instalar MetaMask
              <img src="MetaMask_logo.png" alt="MetaMask Logo" className="LogoMetaMask" />
            </Button>
          </a>
        </div>

        <div className="NotaVentanaInstalarMetamask">
          <p className="LetraAmarilla">
          <b>Nota:</b> Se recomienda enormemente crear un nuevo monedero con una nueva contraseña
          dedicada exclusivamente para jugar en la plataforma. Para ello, si aún nunca creaste un
          monedero con MetaMask, te invitamos a que sigas atentamente los pasos brindados al instalar
          la extensión, y que guardes la clave secreta de recuperación en un lugar seguro.
          </p>
        </div>

      </div>

      <Footer/>
  </div>
  );

}

export default VentanaInstalarMetaMask;