import hljs from 'highlight.js'
import hljsDefineSolidity from 'highlightjs-solidity';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/CodigoResaltado.css';


function NivelCodigo(props) {

  const { nivel } = props;

  function obtenerCodigoResaltado(codigo) {
    hljsDefineSolidity(hljs);
    const codigoResaltado = hljs.highlight(codigo, { language: 'solidity' }).value;
    return (
      <pre><code className='hljs borde-resaltado' dangerouslySetInnerHTML={{ __html: codigoResaltado }} /></pre>
    );
  }

  return (
    <div className="NivelCodigo">
      {nivel && nivel.mostrarCodigoFuente ? (
        <>
          {nivel && nivel.codigoFuente2 ? (
            <>
              {obtenerCodigoResaltado(nivel.codigoFuente2)}
              {obtenerCodigoResaltado(nivel.codigoFuente)}
            </>
          ) : nivel && nivel.codigoFuente ? (
            obtenerCodigoResaltado(nivel.codigoFuente)
          ) : null}
        </>
      ) : (
        <div className="CodigoNoProvisto">
          {nivel.codigoFuente}
        </div>
      )}
    </div>
  );

}

export default NivelCodigo;