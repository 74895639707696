import '../styles/PerfilJugador.css';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import React, { useState, useEffect } from "react";
import ReactFlagsSelect from "react-flags-select";
import { FlagIcon } from "react-flag-kit";
import emailIcon from '../icons/ic-email.svg';
import githubIcon from '../icons/ic-github.svg';
import linkedinIcon from '../icons/ic-linkedin.svg';
import twitterIcon from '../icons/ic-twitter.svg';
import sitioWebIcon from '../icons/ic-link.svg';
import Footer from './Footer.js';

function PerfilJugador(props) {

  const { contratoPlataforma, web3, chainId, addressJugador, niveles, datosDeJugadores, puntosTotales, notificarActualizarInfoJuego, notificarIniciarSesion } = props;

  const [codigoPais, setCodigoPais] = useState("");
  const [nick, setNick] = useState("");
  const [email, setEmail] = useState("");
  const [cuentaGithub, setCuentaGithub] = useState("");
  const [cuentaLinkedIn, setCuentaLinkedIn] = useState("");
  const [cuentaTwitter, setCuentaTwitter] = useState("");
  const [sitioWeb, setSitioWeb] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [nivelesCompletos, setNivelesCompletos] = useState("");
  const [puntosJugador, setPuntosJugador] = useState("");
  const [puestoJugador, setPuestoJugador] = useState("");

  const [direccionPerfilJugadorEsValida, setDireccionPerfilJugadorEsValida] = useState(false);

  const maxLength = 500;

  // Obtenemos el parámetro direccionPerfilJugador de la URL
  let { direccionPerfilJugador } = useParams();

  const navigate = useNavigate();


  async function obtenerDatosDelJugador() {
    if(direccionPerfilJugadorEsValida && datosDeJugadores !== undefined) {
      try {

        const indiceJugador = datosDeJugadores.findIndex(datosDeJugador => datosDeJugador.jugador.toLowerCase() === direccionPerfilJugador.toLowerCase());

        if(indiceJugador === -1) {
          navigate('/la-direccion-del-jugador-no-esta-registrada');
        }
        
        setPuestoJugador(indiceJugador + 1);
        setEmail(datosDeJugadores[indiceJugador].email);
        setCuentaGithub(datosDeJugadores[indiceJugador].cuentaGithub);
        setCuentaLinkedIn(datosDeJugadores[indiceJugador].cuentaLinkedIn);
        setCuentaTwitter(datosDeJugadores[indiceJugador].cuentaTwitter);
        setSitioWeb(datosDeJugadores[indiceJugador].sitioWeb);
        setDescripcion(datosDeJugadores[indiceJugador].descripcion);
        setNick(datosDeJugadores[indiceJugador].nick);
        setCodigoPais(datosDeJugadores[indiceJugador].codigoPais);
        setNivelesCompletos(datosDeJugadores[indiceJugador].nivelesCompletos);
        setPuntosJugador(datosDeJugadores[indiceJugador].puntosTotales);


      } catch (error) {
        
        if (error.code === 'INVALID_ARGUMENT') {
          navigate('/la-direccion-del-jugador-no-esta-registrada');
          //console.error("Error: Rechazaste la transacción");
        } else {
          //console.error("Error al procesar la transacción: ", error);
          console.error(error);
        }
        
        return;
      }
    }
  }



  function esDireccionValida(address) {
    // Expresión regular para verificar la sintaxis de una dirección Ethereum
    const regex = /^(0x)?[0-9a-fA-F]{40}$/;
  
    // Comprobamos si la dirección coincide con la expresión regular
    return regex.test(address);
  }



  function validarDireccionJugador() {
    if (esDireccionValida(direccionPerfilJugador)) {
      setDireccionPerfilJugadorEsValida(true)
    } else {
      navigate('/la-direccion-del-jugador-no-es-valida');
      return;
    }
  }



  useEffect(() => {
    validarDireccionJugador();

  }, [direccionPerfilJugador]);



  useEffect(() => {
    obtenerDatosDelJugador();

  }, [direccionPerfilJugadorEsValida, datosDeJugadores]);



  return (
    <div className="PerfilJugador">

      <header className="Header">
        <h1 className="glitchText" datatext={nick ? nick : "Jugador"}>   
          {codigoPais && (
            <FlagIcon className="IconoBandera" code={codigoPais} />
          )}
        </h1>
    
        <div className="DireccionContainer">
          <span className="DireccionEtiqueta">Dirección: </span>
          <span className="DireccionValor"><strong>{direccionPerfilJugador}</strong></span>
        </div>

        <div className="contenedor">
          <span className="etiqueta">Puesto: </span>
          <span className="valor"><strong>#{puestoJugador}</strong></span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <span className="etiqueta">Puntos: </span>
          <span className="valor"><strong>{puntosJugador.toString()}</strong></span>
        </div>
    
      </header>



      <div className="BodyPerfilJugador">

        {descripcion && (
          <div className="DescripcionJugador">{descripcion}</div>
        )}


        <h4 className="TituloSeccionPerfil">Niveles resueltos</h4>

        <Container className="NivelesGrid">
          <Row>
            {niveles && niveles.map((nivel, index) => {
              return (
                <Col key={index} sm={6} md={4} lg={3}>
                  <div className={`NivelGridElem ${nivelesCompletos[index] ? 'NivelCompletoGridElem' : 'NivelIncompletoGridElem'}`}>
                    {/* Aquí puedes renderizar el contenido de cada nivel */}
                    {nivel.nombre}
                    {/* <p>fdsfsdfsdf</p> */}
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>




        {email || cuentaGithub || cuentaLinkedIn || cuentaTwitter || sitioWeb ? (
          <h4 className="TituloSeccionPerfil">Contacto y redes sociales</h4>
        ) : null}


        <div className="ContenedorRedesSociales">
          {email && (
            <a className="social-link" href={`mailto:${email}`} target="_blank">
              <img src={emailIcon} alt="Icono de E-mail"></img>
              {email}
            </a>
          )}

          {cuentaGithub && (
            <a className="social-link" href={`https://github.com/${cuentaGithub}`} target="_blank">
              <img src={githubIcon} alt="Icono de Github"></img>
              @{cuentaGithub}
            </a>
          )}

          {cuentaLinkedIn && (
            <a className="social-link" href={`https://linkedin.com/in/${cuentaLinkedIn}`} target="_blank">
              <img src={linkedinIcon} alt="Icono de LinkedIn"></img>
              @{cuentaLinkedIn}
            </a>
          )}

          {cuentaTwitter && (
            <a className="social-link" href={`https://twitter.com/${cuentaTwitter}`} target="_blank">
              <img src={twitterIcon} alt="Icono de Twitter"></img>
              @{cuentaTwitter}
            </a>
          )}


          {sitioWeb && (
            <a className="social-link" href={sitioWeb.startsWith("http://") || sitioWeb.startsWith("https://") ? sitioWeb : `http://${sitioWeb}`} target="_blank">
              <img src={sitioWebIcon} alt="Icono de Link"></img>
              {sitioWeb}
            </a>
          )}
        </div>


        <div className="BotonPlataforma">

          <Link key="ranking" to="/ranking">
            <Button variant="outline-success">Ir al Ranking</Button>
          </Link>

          <Link key="plataforma" to="/">
            <Button variant="outline-success">Ir al menú principal</Button>
          </Link>
        </div>


      </div>

        <div className="AyudaFooter">
          <Footer/>
        </div>

    </div>
    
  );
};

export default PerfilJugador;