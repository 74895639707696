import '../styles/Ranking.css';
import { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Footer from './Footer.js';
import VentanaCambiarRed from './VentanaCambiarRed.js';
import { FlagIcon } from "react-flag-kit";


import emailIcon from '../icons/ic-email.svg';
import githubIcon from '../icons/ic-github.svg';
import linkedinIcon from '../icons/ic-linkedin.svg';
import twitterIcon from '../icons/ic-twitter.svg';
import sitioWebIcon from '../icons/ic-link.svg';
import lupaIcon from '../icons/lupa.svg';



function Ranking(props) {

  const { contratoPlataforma, web3, chainId, addressJugador, niveles, datosDeJugadores, actualizarDatosDeJugadores } = props;
  
  const [terminoBusqueda, setTerminoBusqueda] = useState()
  const [datosDeJugadoresFiltrados, setDatosDeJugadoresFiltrados] = useState()
  


  function realizarFiltradoBusqueda() {
    if (terminoBusqueda !== undefined) {
      const jugadoresFiltrados = [];
      for (let i = 0; i < datosDeJugadores.length; i++) {
        const direccionJugador = datosDeJugadores[i].jugador.toLowerCase();
        const nickJugador = datosDeJugadores[i].nick.toLowerCase();
        if (direccionJugador.includes(terminoBusqueda.toLowerCase()) || nickJugador.includes(terminoBusqueda.toLowerCase())) {
          //console.log(`Índice: ${i}, Jugador: ${direccionJugador}`);
          jugadoresFiltrados.push({
            ...datosDeJugadores[i],
            puesto: i + 1,
          });
        }
      }
      //console.log(jugadoresFiltrados);
      setDatosDeJugadoresFiltrados(jugadoresFiltrados);
    }
  }



  useEffect(() => {
    actualizarDatosDeJugadores();
    //console.log("DATOS ACTUALIZADOS!");

  }, []);



  useEffect(() => {
    realizarFiltradoBusqueda();

  }, [terminoBusqueda]);
  


  return (
    <div className="Ranking">

      <VentanaCambiarRed chainId={chainId}/>

      <header className="Header">
        <h1 className="glitchText" datatext={"Ranking"}></h1>
      </header>

      <div className="BodyRanking">


        <div className="BuscadorJugadores">
          <label htmlFor="inputBusqueda">
            <img src={lupaIcon} alt="Icono de Lupa" />
          </label>
          <input id="inputBusqueda" onChange={(e) => setTerminoBusqueda(e.target.value)} placeholder="Buscá a un jugador por su nick o dirección" />
        </div>

        <div className="ContenedorRanking">
          {datosDeJugadores && (
            <>
              {terminoBusqueda && terminoBusqueda !== '' ? (
                <>
                  {/* Caso 1: terminoBusqueda está seteado y no es vacío */}
                  {datosDeJugadoresFiltrados?.length > 0 ? (
                    <>
                      {/* Caso 1.1: datosDeJugadoresFiltrados está seteado y no es vacío:
                        Se itera sobre el arreglo datosDeJugadoresFiltrados:
                      */}
                      <Table className="TablaRanking" striped bordered hover>
                        <thead>
                          <tr>
                            <th>Puesto</th>
                            <th>Nick</th>
                            <th>Dirección</th>
                            <th>Puntos</th>
                            <th>Redes y contacto</th>
                          </tr>
                        </thead>
                        <tbody>
                          {datosDeJugadoresFiltrados.map((datosDeJugador, index) => {
                            //console.log(datosDeJugador);
                            return (
                              <tr key={index}>
                                <td><strong>#{datosDeJugador.puesto}</strong></td>

                                <td>
                                  <span>
                                    <FlagIcon code={datosDeJugador.codigoPais} size="lg" />
                                    &nbsp;&nbsp;{datosDeJugador.nick}
                                  </span>
                                </td>

                                <td>
                                  <Link to={`/jugador/${datosDeJugador.jugador}`}>
                                    {datosDeJugador.jugador}
                                  </Link>
                                </td>
                                {/*<td>{datosDeJugador[0]}</td>*/}
                                <td><strong>{datosDeJugador.puntosTotales.toNumber()}</strong></td>
                                <td className="tdRedesSociales">
                                  {datosDeJugador.email !== '' && (
                                    <a className="LinkIconoPequeñoRedSocial" href={`mailto:${datosDeJugador.email}`} target="_blank" rel="noopener noreferrer">
                                      <img src={emailIcon} alt="emailIcon" />
                                    </a>
                                  )}
                                  {datosDeJugador.cuentaGithub !== '' && (
                                    <a className="LinkIconoPequeñoRedSocial" href={"https://github.com/" + datosDeJugador.cuentaGithub} target="_blank" rel="noopener noreferrer">
                                      <img src={githubIcon} alt="githubIcon" />
                                    </a>
                                  )}
                                  {datosDeJugador.cuentaTwitter !== '' && (
                                    <a className="LinkIconoPequeñoRedSocial" href={"https://twitter.com/" + datosDeJugador.cuentaTwitter} target="_blank" rel="noopener noreferrer">
                                      <img src={twitterIcon} alt="twitterIcon" />
                                    </a>
                                  )}
                                  {datosDeJugador.cuentaLinkedIn !== '' && (
                                    <a className="LinkIconoPequeñoRedSocial" href={"https://linkedin.com/in/" + datosDeJugador.cuentaLinkedIn} target="_blank" rel="noopener noreferrer">
                                      <img src={linkedinIcon} alt="linkedinIcon" />
                                    </a>
                                  )}
                                  {datosDeJugador.sitioWeb !== '' && (
                                    <a className="LinkIconoPequeñoRedSocial" href={datosDeJugador.sitioWeb} target="_blank" rel="noopener noreferrer">
                                      <img src={sitioWebIcon} alt="sitioWebIcon" />
                                    </a>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </>
                  ) : (
                    // Caso 1.2: datosDeJugadoresFiltrados está seteado y sí es vacío
                    // Significa que no encontró resultados de búsqueda
                    <p className="ErrorBusqueda">No se encontraron jugadores que coincidan con el término de búsqueda.</p>
                  )}
                </>
              ) : (
                // Caso 2: terminoBusqueda no está seteado o bien está vacío
                // Se itera sobre datosDeJugadores (no filtrados)
                <>
                  <Table className="TablaRanking" striped bordered hover>
                    <thead>
                      <tr>
                        <th>Puesto</th>
                        <th>Nick</th>
                        <th>Dirección</th>
                        <th>Puntos</th>
                        <th>Redes y contacto</th>
                      </tr>
                    </thead>
                    <tbody>
                      {datosDeJugadores.slice(0, 100).map((datosDeJugador, index) => {
                        //console.log(datosDeJugador);
                        return (
                          <tr key={index}>
                            <td><strong>#{index + 1}</strong></td>

                            <td>
                              <span>
                                <FlagIcon code={datosDeJugador.codigoPais} size="lg" />
                                &nbsp;&nbsp;{datosDeJugador.nick}
                              </span>
                            </td>

                            <td>
                              <Link to={`/jugador/${datosDeJugador.jugador}`}>
                                {datosDeJugador.jugador}
                              </Link>
                            </td>
                            {/*<td>{datosDeJugador[0]}</td>*/}
                            <td><strong>{datosDeJugador.puntosTotales.toNumber()}</strong></td>
                            <td className="tdRedesSociales">
                              {datosDeJugador.email !== '' && (
                                <a className="LinkIconoPequeñoRedSocial" href={`mailto:${datosDeJugador.email}`} target="_blank" rel="noopener noreferrer">
                                  <img src={emailIcon} alt="emailIcon" />
                                </a>
                              )}
                              {datosDeJugador.cuentaGithub !== '' && (
                                <a className="LinkIconoPequeñoRedSocial" href={"https://github.com/" + datosDeJugador.cuentaGithub} target="_blank" rel="noopener noreferrer">
                                  <img src={githubIcon} alt="githubIcon" />
                                </a>
                              )}
                              {datosDeJugador.cuentaTwitter !== '' && (
                                <a className="LinkIconoPequeñoRedSocial" href={"https://twitter.com/" + datosDeJugador.cuentaTwitter} target="_blank" rel="noopener noreferrer">
                                  <img src={twitterIcon} alt="twitterIcon" />
                                </a>
                              )}
                              {datosDeJugador.cuentaLinkedIn !== '' && (
                                <a className="LinkIconoPequeñoRedSocial" href={"https://linkedin.com/in/" + datosDeJugador.cuentaLinkedIn} target="_blank" rel="noopener noreferrer">
                                  <img src={linkedinIcon} alt="linkedinIcon" />
                                </a>
                              )}
                              {datosDeJugador.sitioWeb !== '' && (
                                <a className="LinkIconoPequeñoRedSocial" href={datosDeJugador.sitioWeb} target="_blank" rel="noopener noreferrer">
                                  <img src={sitioWebIcon} alt="sitioWebIcon" />
                                </a>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </>
              )}
            </>
          )}

          {!datosDeJugadores && 
          <div className="MensajeCarga">
            <p>Cargando datos de la blockchain...</p>
            <p className="NotaCarga"><strong>Nota:</strong> Si la carga demora más de cinco segundos, por favor, presioná F5 para recargar la página.</p>
          </div>}

        </div>


        <div className="BotonPlataforma">
          <Link key="plataforma" to="/">
              <Button variant="outline-success">Volver al menú principal</Button>
          </Link>
        </div>

      </div>

      

      <Footer/>
  </div>
  );

}

export default Ranking;