import '../styles/NotFound.css';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Footer from './Footer.js';
import 'bootstrap/dist/css/bootstrap.min.css';


function NotFound() {

  return (
    <div className="NotFound">

      <header className="NotFoundHeader">
          <h1 className="glitchText" datatext="404"></h1>
          <h4 className="LetraRoja">El sitio al que intenta acceder no existe</h4>
      </header>


      <div className="BotonesNotFound">
        <Link key="plataforma" to="/">
            <Button variant="outline-success">Volver al menú principal</Button>
          </Link>
      </div>

      <Footer/>
      
    </div>
  );

}

export default NotFound;