import '../styles/Nivel.css';
import { ethers } from 'ethers'
import { useState, useEffect } from 'react';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import TruffleContract from '@truffle/contract';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import NivelInfo from './NivelInfo.js';
import NivelCodigo from './NivelCodigo.js';
import Footer from './Footer.js';
import VentanaCambiarRed from './VentanaCambiarRed.js';
import VentanaCrearNuevaInstancia from './VentanaCrearNuevaInstancia';
import NivelPesca from './NivelPesca.js';
import { CHAIN_ID as sepoliaChainId } from '../config';



const juego = require("../juego.json");


function Nivel(props) {

  const { contratoPlataforma, web3, chainId, addressJugador, niveles, puntosTotales, notificarActualizarInfoJuego, notificarIniciarSesion } = props;

  const [nivel, setNivel] = useState()
  const [infoMostrada, setInfoMostrada] = useState()
  const [mostrarSWC, setMostrarSWC] = useState()
  const [confirmarCreacion, setConfirmarCreacion] = useState()

  const { id_nivel } = useParams();

  const navigate = useNavigate();

  const addressZero = "0x0000000000000000000000000000000000000000";
  




  async function inicializarProxyDeInstancia() {
    if(nivel.contratoProxy != undefined) {
      const proxyaArtifact = require(`../niveles/${nivel.archivoInstancia}/${nivel.contratoProxy}.json`);

      const truffleContractProxy = TruffleContract(proxyaArtifact);
      truffleContractProxy.defaults({from: addressJugador});
      truffleContractProxy.setProvider(web3.currentProvider);
  
      let _proxy = await truffleContractProxy.at(nivel.instancia);
      window.proxy = _proxy;

    }
  }




  async function inicializarInstanciaWeb3(InstanciaArtifact) {
    const truffleContract = TruffleContract(InstanciaArtifact);
    //truffleContract.setProvider(provider);
    truffleContract.defaults({from: addressJugador});
    truffleContract.setProvider(web3.currentProvider);

    let _instancia = await truffleContract.at(nivel.instancia);

    window.instancia = _instancia;
  }




  async function inicializarInstanciaEthers(InstanciaArtifact) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    const _instancia2 = new ethers.Contract(
      nivel.instancia, 
      InstanciaArtifact.abi,
      signer
    );
    window.instancia2 = _instancia2;
  }





  async function inicializarInstancia() {
    delete window.instancia;
    delete window.instancia2;
    delete window.proxy;

    if(nivel != undefined){
      if(nivel.mostrarCodigoFuente && nivel.instancia != addressZero) {
        const InstanciaArtifact = require(`../niveles/${nivel.archivoInstancia}/${nivel.contratoInstancia}.json`);
        inicializarInstanciaWeb3(InstanciaArtifact);
        inicializarInstanciaEthers(InstanciaArtifact);
        inicializarProxyDeInstancia();
      }
    }
  }





  async function crearInstancia() {
    console.log("%cCreando una instancia del nivel, por favor esperá un momento... ⏳", "color: #5cb2ff; font-size: 16px;")

    try {
      let tx;
      if(nivel.etherInicial == "0") {
        tx = await contratoPlataforma.crearInstanciaDelNivel(nivel.address);
      } else {
        tx = await contratoPlataforma.crearInstanciaDelNivel(nivel.address, {value: ethers.utils.parseEther(nivel.etherInicial.toString())});
      }
      await tx.wait();

    } catch (error) {
      if (error.code === 'ACTION_REJECTED') {
        console.error("Error: Rechazaste la transacción");
      } else {
        console.error("Error al procesar la transacción: ", error);
      }
      return;
    }

    notificarActualizarInfoJuego();
  }




  async function validarInstancia() {
    console.log("%cValidando el estado del nivel, por favor esperá un momento... ⏳", "color: #5cb2ff; font-size: 16px;")

    try {
      const tx = await contratoPlataforma.validarInstanciaDelNivel(nivel.address, nivel.instancia);
      const receipt = await tx.wait();

    } catch (error) {
      if (error.code === 'ACTION_REJECTED') {
        console.error("Error: Rechazaste la transacción");
      } else {
        console.error("Error al procesar la transacción: ", error);
      }
      return;
    }

    let completo = await contratoPlataforma.jugadorCompletoNivel(addressJugador, nivel.address);
    
    if(completo) {
      notificarActualizarInfoJuego();
    } else {
      console.log("%cAún no resolviste el nivel... 🤔 ¡A seguir hackeando! 👨‍💻", "color: #ff8484; font-size: 16px;");
    }
  }




  function mostrarInfo() {
    if(addressJugador != undefined && nivel != undefined) {
      if(chainId == sepoliaChainId) {
        //console.log('%c' + nivel.nombre, 'font-size: 20px;');
        console.clear();
        console.log('%c ' + nivel.nombre + ' ', 'font-size: 20px; background: #141d2b; color: #9fef00;');
        console.log("Plataforma: " + juego.plataforma);
        console.log("Nivel:      " + nivel.address);
        console.log("Jugador:    " + addressJugador);
        if(nivel.instancia != addressZero) {
          //console.log("Instancia:  " + nivel.instancia);
          //console.log("%cInstancia:  " + nivel.instancia, "font-weight: bold;");
          console.log("%cInstancia:  " + nivel.instancia, "font-weight: bold; text-shadow: 2px 2px 4px rgba(0,0,0,0.5);");
        }
        if(nivel.completo) {
          console.log();
          console.log("%c¡Felicitaciones! 🤩 ¡Resolviste el nivel! 🚀", "color: #9fef00; font-size: 16px;");
        }
      }
    }
  }



  function renderizarObjetivos() {
    return (
      <div className="NivelObjetivos">
        <div className="ObjetivoTitulo"><b>Objetivos:</b></div>
        {nivel && nivel.informacion.Objetivos && nivel.informacion.Objetivos.split('<br>').map((item, index) => (
          <div key={index} className="ObjetivoContainer">
            <div key={index} className="Objetivo">
              <span dangerouslySetInnerHTML={{ __html: item }} />
            </div>
          </div>
        ))}
      </div>
    );
  }




  function _mostrarSWC() {
    setMostrarSWC(true);
  }

  


  function validarIdNivel() {
    if (isNaN(id_nivel) || id_nivel < 1 || id_nivel > 30) {
      navigate('/el-nivel-no-existe');
      return;
    }
  }


  

  function normalizarString(str) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }




  useEffect(() => {
    validarIdNivel();

  }, []);



  useEffect(() => {
    if(niveles != undefined) {
      if(chainId == sepoliaChainId) {
        //setInfoMostrada(false);
        setNivel(niveles[id_nivel-1]);
      }
    }

  }, [niveles]);



  
  useEffect(() => {
    if(nivel != undefined){
      mostrarInfo();
      if(nivel.completo) {
        _mostrarSWC();
      }
    }
  }, [nivel]);




  useEffect(() => {
    inicializarInstancia();

  }, [nivel]);






  return (
    <div className="Nivel">

      <VentanaCambiarRed chainId={chainId} setConfirmarCreacion={setConfirmarCreacion}/>
      <VentanaCrearNuevaInstancia confirmarCreacion={confirmarCreacion} setConfirmarCreacion={setConfirmarCreacion} crearInstancia={crearInstancia}/>

      {nivel && (
        <header className="NivelHeader">

          <h1 className="glitchText" datatext={normalizarString(nivel.nombre)}></h1>

          <div className="NivelDificultadYSWC">

            <div className="NivelEstado">
              {nivel.completo ? 
                <h4 className="LetraVerde"><b>Resuelto</b></h4>
                : 
                <h4 className="LetraRoja"><b>No resuelto</b></h4>
              }
            </div>

            <img
              src={
                nivel.categoria === "1"
                  ? '/blue-book.png'
                  : nivel.categoria === "2"
                  ? '/blue-lock.png'
                  : '/blue-locks.png'
              }
              alt="Icono Categoria"
              className="IconoCategoria"
            />

            <span className={`dificultad ${nivel.dificultad === "Muy fácil" ? 'muy-facil' : nivel.dificultad === "Fácil" ? 'facil' : nivel.dificultad === "Normal" ? 'normal' : nivel.dificultad === "Difícil" ? 'dificil' : 'muy-dificil'}`} >
              <h4><b>{nivel.dificultad}</b></h4>
            </span>
            
            <span><h5 className="TextoInfoHeader"><b>Premio:&nbsp;</b> {nivel.puntos.completo} puntos</h5></span>

            {Object.keys(nivel.scsvs).length !== 0 && (
              nivel.puntos.faltantesDesbloqueoSWC === 0 || nivel.completo ? (
                nivel.scsvs && mostrarSWC ? (
                  <h5>
                    {/* <b className="TextoInfoHeader">SWC:&nbsp;</b><a href={`https://swcregistry.io/docs/${nivel.swc[0]}`} target="_blank">{nivel.swc[0]}</a> */}

                    
                    <div>
                      {/*
                      {nivel.swc.map((swcItem, index) => (
                        <span key={index}>
                          <a href={`https://swcregistry.io/docs/${swcItem}`} target="_blank">{swcItem}</a>
                          {index !== nivel.swc.length - 1 && ', '}&nbsp;
                        </span>
                      ))}
                      */}

                    {Object.keys(nivel.swc).length > 0 && (
                      <div className="TextoInfoHeader"><b>SWC:</b>&nbsp;
                        {Object.entries(nivel.swc).map(([swcId, swcDescription], index) => (
                          <span key={swcId}>
                            <a href={`https://swcregistry.io/docs/${swcId}`} target="_blank" title={swcId + ": " + swcDescription}>
                              {swcId}
                            </a>
                            {index !== Object.keys(nivel.swc).length - 1 && ', '}&nbsp;
                          </span>
                        ))}
                        &nbsp;&nbsp;&nbsp;
                      </div>
                    )}

                    </div>
                    
                    <div className="TextoInfoHeader"><b>SCSVS:</b>&nbsp;
                      {Object.keys(nivel.scsvs).map((swcKey, index) => (
                        <span key={swcKey}>
                          <a href={nivel.scsvs[swcKey].url} target="_blank" title={nivel.scsvs[swcKey].categoria + " - " + swcKey + ": " + nivel.scsvs[swcKey].descripcion}>{swcKey}</a>
                          {index < Object.keys(nivel.scsvs).length - 1 ? ', ' : ''}&nbsp;
                        </span>
                      ))}
                    </div>
                  </h5>
                ) : (
                  <p className="card-text"> <Button variant="success" onClick={_mostrarSWC}>Mostrar info sobre SWC y SCSVS</Button></p>
                )
              ) : null
            )}

          </div>


          <div className="Botones">
            <Link key="plataforma" to="/">
              <Button variant="outline-success">Volver al menú principal</Button>
            </Link>

            {nivel && nivel.instancia !== addressZero ? (
              <>
                {nivel.completo ? (
                  <Button onClick={() => setConfirmarCreacion(true)} variant="outline-success">Crear nueva instancia</Button>
                ) : (
                  <Button onClick={crearInstancia} variant="outline-success">Crear nueva instancia</Button>
                )}
                {nivel && !nivel.completo && (
                  <Button onClick={validarInstancia} variant="outline-success">Validar instancia</Button>
                )}
              </>
            ) : (
              <Button onClick={crearInstancia} variant="outline-success">Crear instancia</Button>
            )}
          </div>
        
        </header>
      )}



      <div className="NivelBody">

        {nivel && nivel.completo && (
          <>
            <NivelInfo infoNivel={nivel.informacionResuelto} itemsAccordionExpandidos={['0']} esInfoResuelto={true} />

            {renderizarObjetivos()}

            <NivelCodigo nivel={nivel}/>

            {nivel.nombre == "Pesca" &&
              <NivelPesca notificarIniciarSesion={notificarIniciarSesion} />
            }

            <NivelInfo infoNivel={nivel.informacion} itemsAccordionExpandidos={[]} esInfoResuelto={false} />
          </>
        )}



        {nivel && !nivel.completo && (
          <>
            {renderizarObjetivos()}
            
            <NivelCodigo nivel={nivel} />

            {nivel.nombre == "Pesca" &&
              <NivelPesca notificarIniciarSesion={notificarIniciarSesion} />
            }

            {nivel.itemsAccordionExpandidos !== undefined
              ? <NivelInfo infoNivel={nivel.informacion} itemsAccordionExpandidos={nivel.itemsAccordionExpandidos} esInfoResuelto={false} />
              : <NivelInfo infoNivel={nivel.informacion} itemsAccordionExpandidos={['0']} esInfoResuelto={false} />
            }

          </>
        )}

      </div>


      {nivel && (
        <Footer/>
      )}

    </div>
  );

}

export default Nivel;