import '../styles/Autenticador.css';
import { ethers } from 'ethers'
import { useState, useEffect } from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Footer from './Footer.js';
import VentanaCambiarRed from './VentanaCambiarRed.js';
import RecaptchaFormulario from './RecaptchaFormulario.js';
import { API_URL } from '../config';


function Autenticador(props) {

  const { MetaMaskBloqueado, web3, chainId, addressJugador, notificarSesionIniciada, notificarPrimerIngreso, cerrarSesion } = props;

  const [usuarioAunNoRegistrado, setUsuarioAunNoRegistrado] = useState();
  const [tokenRecaptcha, setTokenRecaptcha] = useState();
  const [nonce, setNonce] = useState();
  const [mensajeError, setMensajeError] = useState();
  const [registroExitoso, setRegistroExitoso] = useState(false); 
  

  async function obtenerNonce() {
    try {
      const response = await axios.post(`${API_URL}/auth/nonce`, {
        address: addressJugador,
      });
  
      const data = response.data;
      //console.log("El nonce es: " + data.nonce);
      setUsuarioAunNoRegistrado(false);
      setNonce(data.nonce);

    } catch (error) {
      if (error.response.status === 400) {
        const errorData = error.response.data;
        if (errorData.error === "Aun no te registraste en la plataforma") {
          console.error(errorData.error);
          setUsuarioAunNoRegistrado(true);
          setNonce(undefined);
          //console.error(errorData.error);
          return;
        }
      }
    }
  }


  
  async function loguearUsuario() {
    try {
      const signature = await web3.eth.personal.sign(
        web3.utils.fromUtf8(`Nonce: ${nonce}`),
        addressJugador,
        ""
      );

      const response = await axios.post(`${API_URL}/auth/login`, {
        address: addressJugador,
        signature: signature,
      }, {
        withCredentials: true,
      });

      const data = response.data;
      //console.log(data.token);
      localStorage.setItem('tokenAddress', data.tokenAddress);
      localStorage.setItem('tokenExpiration', data.tokenExpiration);

      //redirigirAlSitioAnterior();
      notificarSesionIniciada();

    } catch (error) {
      if (error.message.includes("MetaMask Personal Message Signature: User denied message signature.")) {
        console.error("Rechazaste la transacción");
        setMensajeError("Rechazaste la solicitud de firma de MetaMask. Necesitás aceptarla para poder loguearte");
      } else {
        console.error("Ocurrió un error: ", error);
      }
    }
  }



  async function registrarUsuario() {
    //setMensajeError("No completaste el reCAPTCHA. Por favor, volvé a intentarlo nuevamente");
    
    try {
      const response = await axios.post(`${API_URL}/auth/registro`, {
        address: addressJugador,
        tokenRecaptcha: tokenRecaptcha,
      });

      obtenerNonce();
      notificarPrimerIngreso();
      setRegistroExitoso(true);

    } catch (error) {
      if (error.response.status === 400) {
        const errorData = error.response.data;
        if (errorData.error === "El token de reCAPTCHA no superó la validación") {
          setMensajeError("No completaste el reCAPTCHA, volvé a intentarlo nuevamente");
          console.error(errorData.error);
          throw new Error(errorData.error);
        }
      }
    }
    
  }

  
  // Función para establecer el valor del tokenRecaptcha en el estado
  function establecerTokenRecaptcha(token) {
    setTokenRecaptcha(token);
  }


  // Función para corroborar si el jugador volvió a setear en MetaMask un address anterior que ya estaba logueada
  function chequearSiElAddressEstaLogueado() {
    if(addressJugador != undefined) {
      const tokenAddress = localStorage.getItem("tokenAddress");
      if(tokenAddress == addressJugador) {
        //window.history.back();
        //redirigirAlSitioAnterior();
        notificarSesionIniciada();
      }
    }
  }


  
  useEffect(() => {
    chequearSiElAddressEstaLogueado();

  }, [addressJugador]);



  useEffect(() => {
    if(addressJugador != undefined) {
      obtenerNonce();
    }

  }, [addressJugador]);


  useEffect(() => {
    // Eliminar el mensaje de error cuando cambia algo en el estado
    if (mensajeError) {
      setMensajeError("");
    }
  }, [usuarioAunNoRegistrado, nonce, MetaMaskBloqueado]);



  return (
    <div className="Autenticador">

      <VentanaCambiarRed chainId={chainId}/>

      <header className="Header">
        <h1 className="glitchText" datatext={"HACONTI"}></h1>
        <h3 className="LetraVerde"><b>Hackeá Al Contrato No Tan Inteligente</b></h3>
      </header>


      {usuarioAunNoRegistrado && nonce === undefined && !MetaMaskBloqueado && (
        <div className="BodyRegistroAutenticador">

          <div className="TextoRegistroAutenticador">
            <p className="LetraGris">
              ¡Bienvenido a HACONTI, la plataforma de desafíos de ciberseguridad en Smart Contracts
              diseñada para quienes se inician en el campo! ¡Comenzá sin conocimientos previos y
              convertite en un experto en Solidity y en seguridad en contratos inteligentes!
              <br/><br/>
              Como ya contás con MetaMask y una cuenta propia conectada a la red de Sepolia, para
              poder jugar tan solo necesitás registrarte en la plataforma. Para ello solo tenés que
              completar el reCAPTCHA. ¡Luego podrás iniciar sesión con MetaMask tan solo con un click!
            </p>
          </div>

          <div className="BotonesRegistroAutenticador">
            <div className="RegistroAutenticador">
              <RecaptchaFormulario establecerTokenRecaptcha={establecerTokenRecaptcha} />
              <Button onClick={() => registrarUsuario()} type="submit" variant="outline-success" disabled={!tokenRecaptcha}>
                Registrarme en la plataforma
              </Button>
            </div>
          </div>

          <div className="MensajeAutenticador">
            {mensajeError && (
              <div className="MensajeErrorAutenticador">
                <p>{mensajeError}</p>
              </div>
            )}
          </div>

          <div className="NotaRegistroAutenticador">
            <p className="LetraAmarilla">
              <b>Nota:</b> Si estás jugando desde una cuenta previamente creada, con ether en la red principal de
              Ethereum, recomendamos enormemente que instales MetaMask en otro navegador y configures
              el monedero de cero, con una nueva contraseña, exclusivamente para jugar en la plataforma.
            </p>
          </div>

        </div>
      )}
    

    {!usuarioAunNoRegistrado && nonce !== undefined && !MetaMaskBloqueado && (
      <div className="BodyLoginAutenticador">

        <div className="TextoLoginAutenticador">
          {!usuarioAunNoRegistrado && nonce !== undefined && !MetaMaskBloqueado ? (
            registroExitoso ? (
              <p className="LetraVerde">  
                ¡Te has registrado exitosamente en HACONTI! ¡Iniciá sesión con MetaMask y comenzá a jugar!</p>
            ) : (
              <p className="LetraGris">  
                ¡Bienvenido a HACONTI! ¡Iniciá sesión con MetaMask y comenzá a jugar!</p>
            )
          ) : MetaMaskBloqueado ? (
            <p className="LetraGris">  
              ¡Bienvenido a HACONTI! Por favor, desbloqueá MetaMask para continuar</p>
          ) : null}
        </div>

        <div className="BotonLoginAutenticador" >
          <Button onClick={loguearUsuario} variant="outline-success">
            Iniciar sesión con MetaMask
            <img src="/MetaMask_logo.png" alt="MetaMask Logo" className="LogoMetaMask" />
          </Button>
        </div>

        <div className="MensajeAutenticador">
          {mensajeError && (
            <div className="MensajeErrorAutenticador">
              <p>{mensajeError}</p>
            </div>
          )}
        </div>

      </div>
    )}


    {MetaMaskBloqueado && (
      <div className="BodyDesbloqueoMetaMaskAutenticador">
        <div className="TextoDesbloqueoMetaMaskAutenticador">
          <p className="LetraGris">  
          ¡Bienvenido a HACONTI! Por favor, desbloqueá MetaMask para continuar
          </p>
        </div>
      </div>
    )}

    <Footer/>

  </div>

)}

export default Autenticador;