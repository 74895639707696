import { useState } from 'react';
import { useEffect } from 'react';
//import React, { lazy, Suspense } from 'react'
import { isMobile } from 'react-device-detect';
import ReactDOM from 'react-dom/client';
import Autenticador from './componentes/Autenticador';
import App from './componentes/App';
import Nivel from './componentes/Nivel';
import Ranking from './componentes/Ranking';
import Ayuda from './componentes/Ayuda';
import Donar from './componentes/Donar';
import EditarPerfil from './componentes/EditarPerfil';
import PerfilJugador from './componentes/PerfilJugador';
import NotFound from './componentes/NotFound';
import VentanaParaMovil from './componentes/VentanaParaMovil';
import VentanaInstalarMetaMask from './componentes/VentanaInstalarMetaMask';
import reportWebVitals from './reportWebVitals';
import { ethers } from 'ethers'
import PlataformaArtifact from "./Plataforma.json";
import pkgWeb3 from "web3";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { API_URL } from './config';
//import { CHAIN_ID as sepoliaChainId } from './config';

import ScrollToTop  from './componentes/ScrollToTop';


// CSS media query para detectar tabletas o dispositivos pequeños
const isTabletOrSmallDevice = window.matchMedia("(max-width: 768px)").matches;


const juego = require("./juego.json");

/*
// Para dividir el paquete sin carga diferida.
const nonlazy = (component) => lazy(() => component);

const Autenticador = nonlazy(import('./componentes/Autenticador'));
const App = nonlazy(import('./componentes/App'));
const Nivel = nonlazy(import('./componentes/Nivel'));
const NotFound = nonlazy(import('./componentes/NotFound'));
*/


const root = ReactDOM.createRoot(document.getElementById('root'));

//const sepoliaChainId = "31337";
//const sepoliaChainId = "11155111";


function Index() {
  const [web3, setWeb3] = useState(null);
  const [chainId, setChainId] = useState(null);
  const [addressJugador, setAddressJugador] = useState(null);
  const [infoJuego, setInfoJuego] = useState(null);
  const [puntosTotales, setPuntosTotales] = useState(null);
  const [datosJugador, setDatosJugador] = useState(null);
  const [datosDeJugadores, setDatosDeJugadores] = useState(null);
  const [niveles, setNiveles] = useState(null);
  const [contratoPlataforma, setContratoPlataforma] = useState(null);

  const [informacion, setInformacion] = useState(null);
  const [codigoFuente, setCodigoFuente] = useState(null);

  const [MetaMaskInstalado, setMetaMaskInstalado] = useState(null);
  const [MetaMaskBloqueado, setMetaMaskBloqueado] = useState(null);
  const [faltaInstalarMetaMask, setFaltaInstalarMetaMask] = useState(null);
  const [actualizarInfoJuego, setActualizarInfoJuego] = useState(null);

  const [sesionIniciada, setSesionIniciada] = useState(null);
  const [primerIngreso, setPrimerIngreso] = useState(null);


  let interval;
  let interval2;

  

  function cargarTexto(data) {
    return new Promise((resolve, reject) => {
      // console.log(`loading file:`, data, __dirname)
      try {
        fetch(data)
          .then(response => response.text())
          .then(text => {
            // console.log(`file loaded`)
            resolve(text)
          })
      }
      catch(error) {
        console.log(`Error cargando archivo: `, error)
        reject(error)
      }
    })
  }

  
  async function cargarInformacionDeNiveles() {
    if(MetaMaskInstalado != undefined) {
      let _informacion = []
      for (let _nivel of juego.niveles) {
        let info = {};
        for(let archivoInfo in _nivel.informacion) {
          let ruta = require(`./niveles/${_nivel.archivoInstancia}/${archivoInfo}.md`);
          let texto = await cargarTexto(ruta)
          info[_nivel.informacion[archivoInfo]] = texto;
        }
        _informacion.push(info);
      }
      setInformacion(_informacion);
    }
  }


  async function cargarCodigoFuenteDeNiveles() {
    if(MetaMaskInstalado != undefined) {
      let _codigoFuente = []
      for (let _nivel of juego.niveles) {

        if(_nivel.mostrarCodigoFuente) {

          let fuentes = {}

          let ruta_codigo_fuente = require(`./niveles/${_nivel.archivoInstancia}/${_nivel.archivoInstancia}.sol`);
          let codigo_fuente = await cargarTexto(ruta_codigo_fuente);
          fuentes.codigoFuente = codigo_fuente

          if(_nivel.contratoInstancia2 != undefined) {
            let ruta_codigo_fuente2 = require(`./niveles/${_nivel.archivoInstancia}/${_nivel.contratoInstancia2}.sol`);
            let codigo_fuente2 = await cargarTexto(ruta_codigo_fuente2);
            fuentes.codigoFuente2 = codigo_fuente2;
          }

          _codigoFuente.push(fuentes);
        
        } else {
          _codigoFuente.push({ codigoFuente: "Código fuente no provisto" });
        }

      }

      setCodigoFuente(_codigoFuente);
    }
  }



  function obtenerPuntosFaltantesDesbloqueo(puntosTotales, puntosDesbloqueo) {
    if (puntosTotales >= puntosDesbloqueo) {
      return 0;
    } else {
      return puntosDesbloqueo - puntosTotales;
    }
  }


  async function obtenerCuentaJugador() {
    //if(web3 != undefined) {
    if(chainId != undefined && web3 != undefined) {
      //setAddressJugador(null);
      try {
        let cuentas = await web3.eth.getAccounts();
        // Mientras el jugador no haya desbloqueado MetaMask, seguiremos
        // consultando cada 1 segundo las cuentas para determinar si lo desbloqueó
        while(cuentas.length === 0) {
          setMetaMaskBloqueado(true);
          // Espera un segundo antes de volver a verificar el estado de MetaMask
          await new Promise(resolve => setTimeout(resolve, 1000));
          cuentas = await web3.eth.getAccounts();
        }
        // Si el address del jugador cambió, limpiamos el intervalo de monitoreo, los niveles y cerramos sesión en la API
        if(addressJugador != null && addressJugador != undefined && addressJugador != cuentas[0].toLowerCase()) {
          setNiveles(null);
          clearInterval(interval2);
          await cerrarSesion();
        }
        setAddressJugador(cuentas[0].toLowerCase());
        window.jugador = cuentas[0].toLowerCase();
        setMetaMaskBloqueado(false);
      } catch (error) {
          console.error("Error al obtener la cuenta del jugador: ", error);
          return null;
      }
    }
  }



  async function cerrarSesion() {
    try {
      const response = await fetch(`${API_URL}/auth/logout`, {
        method: 'POST',
        credentials: 'include' // Incluye las cookies en la solicitud
      });
    
      if (response.status === 200) {
        // Eliminar los datos relacionados con la sesión del usuario del almacenamiento local
        localStorage.removeItem("tokenAddress");
        localStorage.removeItem("tokenExpiration");
        // Actualizar el estado para reflejar que el usuario ha cerrado sesión
        setSesionIniciada(false);
        // Realizar cualquier otra acción necesaria después de cerrar sesión
        // ...
        console.log("¡Sesión cerrada exitosamente!");
      } else {
        // Manejar otros códigos de estado de respuesta, si es necesario
        console.error("Error al cerrar sesión:", response.status);
      }
    } catch (error) {
      console.error("Error al realizar la solicitud de cerrar sesión:", error);
    }
  }



  async function solicitarDesbloqueoMetaMask() {
    if(chainId != undefined && web3 != undefined && MetaMaskBloqueado) {
      try {
        // Al hacer esta solicitud se abre una la ventana de MetaMask para solicitar al jugador que desbloquee la wallet
        await window.ethereum.request({ method: 'eth_requestAccounts' });
      } catch (error) {
        // Si ya había una solicitud previa para que el jugador debloquee la wallet, no se muestra
        if (error.message !== "Already processing eth_requestAccounts. Please wait.") {
          console.error("Error al desbloquear MetaMask: ", error);
        }
      }
    }
  }


  async function consultarChainIdActual() {
    let _chainId;

    try {
      _chainId = await web3.eth.net.getId();
    // Si entra al catch es porque está conectado a un nodo local que no está activo. Seteamos el chain a 31337
    // para que se detone el cambio de red. El problema actualmente es que tarda unos 20 segundos hasta entrar al catch
    // y en ese entonces el intervalo ejecutó varias veces el método obtenerRedActual(), lo que lleva a que siga saltando
    // temporalmente el error de que el jugador está conectado a la red incorrecta, por más que ya haya switcheado a Sepolia
    } catch(error) {
      _chainId = "31337";
    }

    return _chainId;
  }



  async function obtenerRedActual() {
    if(web3 != undefined) {

      let _chainId = await consultarChainIdActual();

      //console.log("CHAIN ID: ", _chainId);

      setChainId(_chainId);
    }
  }



  async function restablecerMonitoreoSiLaRedCambio() {

    // Por más que desde obtenerRedActual() se setee una nueva chainId 
    // en el interval de monitorearCambiosAddressJugador() se sigue tomando
    // el chainId viejo. Por lo tanto, en caso de que haya cambiado
    // limpiamos el interval, para que en el nuevo interval que se setee
    // se emplee la nueva chainId

    if(web3 != undefined) {

      let _chainId = await consultarChainIdActual();

      if(_chainId != chainId) {
        clearInterval(interval2);
        //setChainId(chainId);
      }
    }
  }



  function verificarToken() {
    if(addressJugador != undefined) {
      const tokenAddress = localStorage.getItem("tokenAddress");
      const tokenExpiration = localStorage.getItem("tokenExpiration");
      
      //console.log("TOKEN EXPIRATION: " + tokenExpiration);
      //console.log("TOKEN ADDRESS: " + tokenAddress)

      if(tokenAddress != addressJugador || tokenExpiration < Date.now()) {
        setSesionIniciada(false);

      } else {
        setSesionIniciada(true);
        //console.log("TOKEN VALIDO :D");
      }
    }
  }



  async function obtenerInfoJuego() {
    if(sesionIniciada != undefined || actualizarInfoJuego != undefined) {
      if(sesionIniciada || actualizarInfoJuego) {
        try {
          const response = await fetch(`${API_URL}/info/juego`, {credentials: 'include'});

          if (response.status === 401) {
            localStorage.removeItem("tokenAddress");
            localStorage.removeItem("tokenExpiration");
            setSesionIniciada(false);
            return;
          }

          const infoJuego = await response.json();
          setInfoJuego(infoJuego.infoJuego);
          setDatosJugador(infoJuego.infoJuego.datosJugador);
          setPuntosTotales(infoJuego.infoJuego.datosJugador.puntosTotales);
        } catch (error) {
          console.error(error);
        }
      }
      setActualizarInfoJuego(false);
    }
  }



  async function obtenerInfoJugadores() {
    if(sesionIniciada != undefined) {
      if(sesionIniciada) {
        try {
          let _datosDeJugadores = await contratoPlataforma.obtenerDatosDeJugadores();
          let datosDeJugadoresAux = [..._datosDeJugadores];

          // Ordenar por el número en orden descendente
          const datosDeJugadoresOrdenados = datosDeJugadoresAux.sort((datosJugadorA, datosJugadorB) => {
            const datosJugadorADecimal = datosJugadorA.puntosTotales.toNumber();
            const datosJugadorBDecimal = datosJugadorB.puntosTotales.toNumber();
            return datosJugadorBDecimal - datosJugadorADecimal;
          });

          setDatosDeJugadores(datosDeJugadoresOrdenados);
          //console.log("Datos de jugadores seteados")
          //console.log(datosDeJugadoresOrdenados)
        } catch (error) {
          console.error(error);
        }
      }
    }
  }



  async function inicializarPlataforma() {
    if(MetaMaskInstalado != undefined) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const plataforma = new ethers.Contract(
        juego.plataforma,
        PlataformaArtifact.abi,
        signer
      );
      window.plataforma = plataforma;
      setContratoPlataforma(plataforma);
    }
  }



  function chequearSiMetaMaskEstaInstalado() {
    if (typeof window.ethereum !== 'undefined' && !faltaInstalarMetaMask) {
      console.log('MetaMask está disponible en este navegador.');
      setMetaMaskInstalado(true);
    } else {
      console.log('MetaMask no está instalado en este navegador.');
      setFaltaInstalarMetaMask(true);
    }
  }



  // Devuelve true si MetaMask está bloqueado
  async function MetaMaskEstaBloqueado() {
    try {
        const accounts = await web3.eth.getAccounts();
        return accounts.length == 0;
    } catch (error) {
        console.error("Error al verificar el estado de Metamask:", error);
        return true;
    }
  }


  /*
  async function obtenerCuentaJugador_web3() {
    try {
        const accounts = await web3.eth.getAccounts();
        return accounts.length > 0 ? accounts[0] : null;
    } catch (error) {
        console.error("Error al obtener la primera dirección de la cuenta:", error);
        return null;
    }
  }
  */


  function inicializarWeb3() {
    if(MetaMaskInstalado != undefined) {
      let _web3 = new pkgWeb3(window.ethereum);
      setWeb3(_web3);
      window.web3 = _web3;

      window.ethers = ethers;
    }
  }

  

  async function monitorearCambiosAddressJugador() {
    if (addressJugador != undefined && web3 != undefined) {


      if(await MetaMaskEstaBloqueado()) {
        //setAddressJugador(null); // ver si dejarlo o no
        setMetaMaskBloqueado(true);

        // Si el jugador bloqueó MetaMask y todavía tiene tokenAddress en el localStorage, quitamos la cookie JWT del navegador
        if (localStorage.getItem("tokenAddress")) {
          await cerrarSesion();
        }

      } else {
        
        await restablecerMonitoreoSiLaRedCambio();

        await obtenerCuentaJugador();
      
        /*
        let [nuevaCuenta] = await window.ethereum.request({ method: 'eth_requestAccounts' });
  
        if (nuevaCuenta !== addressJugador) {
          setAddressJugador(nuevaCuenta);
          window.jugador = nuevaCuenta;
          //clearInterval(interval2);
        }
        */
      }
    }
  }




  function insertarInformacionSWCySCSVS(nivel) {
    if (nivel.completo) {
      // Eliminamos la información de SWC y SCSVS en caso de que haya sido cargada previamente
      if (nivel.informacion && nivel.informacion["Información sobre SWC y SCSVS"]) {
        delete nivel.informacion["Información sobre SWC y SCSVS"];
      }

      // Si el nivel está completo, inserta Información sobre SWC y SCSVS en la segunda posición de "informacionResuelto"
      const entries = Object.entries(nivel.informacionResuelto);
      nivel.informacionResuelto = entries.reduce((acumulador, [key, value], index) => {
        if (index === 1) { // Inserta la nueva información en la segunda posición si hay solo un elemento
          acumulador["Información sobre SWC y SCSVS"] = nivel.SWCySCSVS;
        }
        acumulador[key] = value; // Copia la información existente
        return acumulador;
      }, {});
  
      // Si el nivel está completo y solo hay un item en "informacionResuelto", la info de SWC y SCSVS se agrega al final
      if (entries.length === 1) {
        nivel.informacionResuelto["Información sobre SWC y SCSVS"] = nivel.SWCySCSVS;
      }
    } else {
      // Sino, la inserta al final de "informacion"
      nivel.informacion["Información sobre SWC y SCSVS"] = nivel.SWCySCSVS;
    }
  }



  async function fusionarInfoNiveles() {
    if(infoJuego != undefined && informacion != undefined && codigoFuente != undefined) {
      let _niveles = juego.niveles;
      for(let i = 0; i < _niveles.length; i++) {
        _niveles[i].puntos.faltantesDesbloqueoHints = obtenerPuntosFaltantesDesbloqueo(infoJuego.datosJugador.puntosTotales, _niveles[i].puntos.desbloqueoHints);
        _niveles[i].puntos.faltantesDesbloqueoHintsAdicionales = obtenerPuntosFaltantesDesbloqueo(infoJuego.datosJugador.puntosTotales, _niveles[i].puntos.desbloqueoHintsAdicionales);
        _niveles[i].puntos.faltantesDesbloqueoSWC = obtenerPuntosFaltantesDesbloqueo(infoJuego.datosJugador.puntosTotales, _niveles[i].puntos.desbloqueoSWC);
        _niveles[i].codigoFuente = codigoFuente[i].codigoFuente;
        _niveles[i].codigoFuente2 = codigoFuente[i].codigoFuente2;
        _niveles[i].informacion = informacion[i];
        _niveles[i] = { ..._niveles[i], ...infoJuego.infoNiveles[i] };
        _niveles[i].informacion["Hints"] = _niveles[i].hints;
        _niveles[i].informacion["Hints adicionales"] = _niveles[i].hintsAdicionales;

        insertarInformacionSWCySCSVS(_niveles[i]);
        
        delete _niveles[i].hints;
        delete _niveles[i].hintsAdicionales;
      }

      setNiveles([..._niveles]);
      //console.log(_niveles);
      
    }
  }



  useEffect(() => {
    //console.log("ES MOVIL");
    //console.log(isMobile)
    //console.log("ES TABLET O SMALL DEVICE");
    //console.log(isTabletOrSmallDevice)
    chequearSiMetaMaskEstaInstalado();
  
  }, []);


  useEffect(() => {
    inicializarPlataforma();
  
  }, [MetaMaskInstalado]);



  useEffect(() => {
    inicializarWeb3();
  
  }, [MetaMaskInstalado]);



  useEffect(() => {
    cargarCodigoFuenteDeNiveles();
  
  }, [MetaMaskInstalado]);



  useEffect(() => {
    cargarInformacionDeNiveles();
  
  }, [MetaMaskInstalado]);



  useEffect(() => {
    obtenerRedActual();
    interval = setInterval(obtenerRedActual, 5 * 1000);

  }, [web3]);



  useEffect(() => {
    obtenerCuentaJugador();
  
  }, [chainId, MetaMaskBloqueado]);


  useEffect(() => {
    solicitarDesbloqueoMetaMask();
  
  }, [chainId, MetaMaskBloqueado]);



  useEffect(() => {
    verificarToken();
  
  }, [addressJugador]);



  useEffect(() => {
    if (!interval2) {
      interval2 = setInterval(monitorearCambiosAddressJugador, 1000);
    }
  
  }, [addressJugador]);


  useEffect(() => {
    obtenerInfoJuego();
    obtenerInfoJugadores();
  
  }, [sesionIniciada, actualizarInfoJuego]);



  useEffect(() => {
    fusionarInfoNiveles();
  
  }, [infoJuego, informacion, codigoFuente]);




  // useEffect para limpiar intervalos
  useEffect(() => {
    // Limpieza de ambos intervalos cuando el componente se desmonta
    return () => {
      clearInterval(interval);
      clearInterval(interval2);
    };
  }, []);



  return (
    <div>
      {isMobile || isTabletOrSmallDevice ? (
        <VentanaParaMovil/>
      ) : !MetaMaskInstalado ? (
        <VentanaInstalarMetaMask/>
      ) : !sesionIniciada || MetaMaskBloqueado ? (
        <Autenticador MetaMaskBloqueado={MetaMaskBloqueado} web3={web3} chainId={chainId} addressJugador={addressJugador} notificarSesionIniciada={() => setSesionIniciada(true)} notificarPrimerIngreso={() => setPrimerIngreso(true)} />
      ) : primerIngreso ? (
        <Ayuda contratoPlataforma={contratoPlataforma} web3={web3} chainId={chainId} addressJugador={addressJugador} niveles={niveles} puntosTotales={puntosTotales} notificarIniciarSesion={() => setSesionIniciada(false)} primerIngreso={primerIngreso} notificarPrimerIngresoRealizado={() => setPrimerIngreso(false)} />
      ) : (

        <Router>

          <ScrollToTop />

          {/*<Suspense fallback={<div>Loading...</div>}>*/}
          <Routes>
            { contratoPlataforma !== undefined && niveles !== undefined && puntosTotales !== undefined && datosJugador !== undefined && datosDeJugadores !== undefined ? (
            <>
            <Route exact path="/" element={<App contratoPlataforma={contratoPlataforma} web3={web3} chainId={chainId} addressJugador={addressJugador} niveles={niveles} datosJugador={datosJugador} puntosTotales={puntosTotales} notificarIniciarSesion={() => setSesionIniciada(false)} cerrarSesion={cerrarSesion} />} />
            <Route path="/nivel/:id_nivel" element={<Nivel contratoPlataforma={contratoPlataforma} web3={web3} chainId={chainId} addressJugador={addressJugador} niveles={niveles} puntosTotales={puntosTotales} notificarActualizarInfoJuego={() => setActualizarInfoJuego(true)} notificarIniciarSesion={() => setSesionIniciada(false)}/>} />
            <Route path='/ranking' element={<Ranking contratoPlataforma={contratoPlataforma} web3={web3} chainId={chainId} addressJugador={addressJugador} niveles={niveles} datosDeJugadores={datosDeJugadores} actualizarDatosDeJugadores={obtenerInfoJugadores} puntosTotales={puntosTotales} notificarIniciarSesion={() => setSesionIniciada(false)}/>} />
            <Route path='/ayuda' element={<Ayuda contratoPlataforma={contratoPlataforma} web3={web3} chainId={chainId} addressJugador={addressJugador} niveles={niveles} puntosTotales={puntosTotales} notificarIniciarSesion={() => setSesionIniciada(false)} primerIngreso={primerIngreso} notificarPrimerIngresoRealizado={() => setPrimerIngreso(false)}/>} />
            <Route path='/donar' element={<Donar contratoPlataforma={contratoPlataforma} web3={web3} chainId={chainId} addressJugador={addressJugador} niveles={niveles} puntosTotales={puntosTotales} notificarIniciarSesion={() => setSesionIniciada(false)} primerIngreso={primerIngreso} notificarPrimerIngresoRealizado={() => setPrimerIngreso(false)}/>} />
            <Route path='/perfil' element={<EditarPerfil contratoPlataforma={contratoPlataforma} web3={web3} chainId={chainId} addressJugador={addressJugador} niveles={niveles} datosJugador={datosJugador} puntosTotales={puntosTotales} notificarIniciarSesion={() => setSesionIniciada(false)} primerIngreso={primerIngreso} notificarPrimerIngresoRealizado={() => setPrimerIngreso(false)}/>} />
            <Route path='/jugador/:direccionPerfilJugador' element={<PerfilJugador contratoPlataforma={contratoPlataforma} web3={web3} chainId={chainId} addressJugador={addressJugador} niveles={niveles} datosDeJugadores={datosDeJugadores} puntosTotales={puntosTotales} notificarIniciarSesion={() => setSesionIniciada(false)} primerIngreso={primerIngreso} notificarPrimerIngresoRealizado={() => setPrimerIngreso(false)}/>} />
            </>
            ) : null}
            <Route path="*" element={<NotFound />} />
          </Routes>
          {/*</Suspense>*/}
        </Router>

      )}
    </div>
  );
}

root.render(<Index />);


// Si deseas comenzar a medir el rendimiento en tu aplicación, pasa una función
// para registrar los resultados (por ejemplo: reportWebVitals(console.log))
// o enviarlos a un punto final de análisis. Aprende más en: https://bit.ly/CRA-vitals
reportWebVitals();
