import '../styles/App.css';
import '../styles/VentanaParaMovil.css';
import Button from 'react-bootstrap/Button';
import Footer from './Footer.js';



function VentanaParaMovil(props) {

  return (
    <div className="VentanaParaMovil">

      <header className="HeaderMovil">
        <h1 className="glitchTextMovil" datatext={"HACONTI"}></h1>
        <h3 className="NombrePlataformaMovil"><b>Hackeá Al Contrato No Tan Inteligente</b></h3>
      </header>

      <div className="TextoVentanaParaMovil">
        <p className="LetraGris">
          ¡Bienvenido a HACONTI, la plataforma de desafíos de ciberseguridad en Smart Contracts
          diseñada para quienes se inician en el campo! ¡Comenzá sin conocimientos previos y
          convertite en un experto en Solidity y en seguridad en contratos inteligentes!
          <br/><br/><br/>
          ¿Qué esperás para jugar? ¡Accedé a HACONTI desde tu computadora y aprendé a explotar 
          las vulnerabilidades en contratos inteligentes que en el mundo real condujeron al robo 
          de millones de dólares en criptomonedas!
          <br/>
        </p>
      </div>

      <footer className="FooterMovil">
        {/* Contenido del footer */}
        <p>© 2024 HACONTI: Hackeá Al Contrato No Tan Inteligente. Todos los derechos reservados.</p>
      </footer>
  </div>
  );

}

export default VentanaParaMovil;