import '../styles/App.css';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import CardNivel from './CardNivel.js';
import Footer from './Footer.js';
import VentanaCambiarRed from './VentanaCambiarRed.js';
import { FlagIcon } from "react-flag-kit";
import { CHAIN_ID as sepoliaChainId } from '../config';


const juego = require("../juego.json");


function App(props) {

  const { contratoPlataforma, web3, chainId, addressJugador, niveles, datosJugador, puntosTotales, cerrarSesion } = props;
  

  function mostrarInfo() {

    if(addressJugador != undefined) {
      if(chainId == sepoliaChainId) {
        console.clear();
        console.log('%c HACONTI ', 'font-size: 20px; background: #141d2b; color: #9fef00;');
        console.log("Plataforma: " + juego.plataforma);
        console.log("Jugador:    " + addressJugador);
      }
    }
  }



  useEffect(() => {
    delete window.instancia;
    delete window.instancia2;
    delete window.proxy;
    mostrarInfo();

  }, [addressJugador]);




  return (
    <div className="App">

      <VentanaCambiarRed chainId={chainId}/>

      {/*
      <header className="Header">
        <h1 className="glitchText" datatext={"Plataforma"}></h1>
        {addressJugador && puntosTotales != 'undefined' && (
          <h4>Puntos:&nbsp;<b>{puntosTotales}</b>&nbsp;| Jugador: {addressJugador}&nbsp;&nbsp;
            <Link key="ranking" to="/ranking">
              <Button variant="outline-dark">Ranking</Button>
            </Link>
          </h4>
        )}
      </header> */}


      <header className="Header">
        <h1 className="glitchText" datatext={"Haconti"}></h1>
        <h3 className="LetraVerde"><b>Hackeá Al Contrato No Tan Inteligente</b></h3>
          {/*
          <Link key="ranking" to="/ranking">
            <Button variant="outline-dark">Ranking</Button>
          </Link>
          */}

          {/*
          <div className="PuntosJugador">
            <h3>Puntos:&nbsp;<b>{puntosTotales}</b></h3>
            <b>Address Jugador:</b>
            <p>{addressJugador}</p>
            <Link key="ranking" to="/ranking">
              <Button variant="outline-success">Ranking</Button>
            </Link>
          </div>
        */}
      </header>


      {niveles && (
      <div className="body-container">

        <div className="custom-navbar">
          <div className="nav-items">

            <div className="nav-infoJugador">

              {datosJugador.nick && datosJugador.nick !== '' ? (
                <span className="nav-elem">
                  <strong>
                    Jugador:&nbsp;&nbsp;
                    <FlagIcon style={{ marginBottom: '4px' }} code={datosJugador.codigoPais} size="lg" /> {datosJugador.nick} 
                    <span className="jugador-address"> ({addressJugador})</span>
                  </strong>
                </span>
              ) : (
                <span className="nav-elem">
                  <strong>
                    Jugador:&nbsp;&nbsp;
                    <span className="jugador-address">{addressJugador}</span>
                  </strong>
                </span>
              )}
                        
              <span className="nav-elem">
                <strong>Puntos:&nbsp;&nbsp;{puntosTotales}</strong>
              </span>

            </div>

            <div className="nav-botones">

              <Link className="nav-elem" to="/perfil">
                <Button variant="outline-success">Editar&nbsp;perfil</Button>
              </Link>
              <Link className="nav-elem" to="/ranking">
                <Button variant="outline-success">Ranking</Button>
              </Link>
              <Link className="nav-elem" to="/ayuda">
                <Button variant="outline-success">Ayuda</Button>
              </Link>
              <Link className="nav-elem" to="/donar">
                <Button variant="outline-success">Donar</Button>
              </Link>
              <a className="nav-elem" href="https://docs.google.com/forms/d/e/1FAIpQLSdNbzGB56AFofFgift1QbQnzhOLj6_j8jpUQLmD37u6AIxttA/viewform?usp=sf_link" target="_blank" rel="noopener noreferrer">
                <Button variant="outline-success" className="encuesta-button"><strong>Encuesta</strong></Button>
              </a>
              <a className="nav-elem">
                <Button  variant="outline-success" onClick={cerrarSesion}>Cerrar&nbsp;Sesión</Button>
              </a>
            
            </div>

          </div>

        </div>
        

          <div className="Niveles">
            {niveles.map((nivel)  => (
              <CardNivel nivel={nivel} key={nivel.id}/>
            ))}
            
          </div>
         
      </div>
      )}


      <div className="TextoCarga">
        {!niveles && <p className="LetraGris">Cargando datos de la blockchain...</p>}
      </div>
 

      <Footer/>

    </div>
  );

}

export default App;