import 'bootstrap/dist/css/bootstrap.min.css';

function Footer() {

  return(
    <footer className="Footer">
      {/* Contenido del footer */}
      <p>© 2024 HACONTI: Hackeá Al Contrato No Tan Inteligente. Todos los derechos reservados.</p>
    </footer>
  );

}

export default Footer;