import '../styles/Donar.css';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Footer from './Footer.js';


function Donar(props) {

  const { contratoPlataforma, web3, chainId, addressJugador, niveles, puntosTotales, notificarIniciarSesion, primerIngreso, notificarPrimerIngresoRealizado } = props;

  const [errorDonar, setErrorDonar] = useState()
  const [successDonar, setSuccessDonar] = useState()

  const addressDonacion = "0x70480606ECb96063Db549F85208D550f9c259074"



  const handleClick = () => {
    // Redireccionar a la página principal
    //window.location.href = '/';
    
    // Desplazar al principio de la página después de la redirección
    //window.scrollTo(0, 0);

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant' // Esto hará que el desplazamiento sea instantáneo
    });
  };



  const copiarAlPortapapeles = () => {
    navigator.clipboard.writeText(addressDonacion)
      .then(() => {
        setSuccessDonar('¡La dirección para donar fue copiada al portapapeles!');
        //alert('¡URL copiada al portapapeles!');
      })
      .catch((error) => {
        console.error('Error al copiar al portapapeles: ', error);
        setErrorDonar('Error al copiar al portapapeles');
      });
  };


  return (
    <div className="Donar">

      {primerIngreso ? (
        <header className="Header">
          <h1 className="glitchText" datatext={"HACONTI"}></h1>
          <h3 className="LetraVerde"><b>Hackeá Al Contrato No Tan Inteligente</b></h3>
        </header>
      ) : ( 
        <header className="Header">
          <h1 className="glitchText" datatext={"Donar"}></h1>
        </header>
      )}


      <div className='BodyDonar'>

        <div className="DonarContenedor">
          <p className="DonarParrafo">HACONTI es una plataforma sin ánimo de lucro desarrollada durante una tesina de grado que funciona gracias 
            a las donaciones voluntarias de sus usuarios.
          </p>
            
          <p className="DonarParrafo">Si estás disfrutando de HACONTI y deseas contribuir a su continuidad, podés realizar una 
            donación transfiriendo la cantidad de ether que prefieras a la siguiente dirección (en Mainnet):
          </p>

          <div className="DonarDireccionContenedor">
            <div className="DonarDireccionYBoton">
              <div className="DonarDireccion">
                <b>{addressDonacion}</b>
              </div>
              <Button variant="outline-success" onClick={copiarAlPortapapeles}>Copiar dirección</Button>
            </div>

            <div className="DonarDireccionEstadoContenedor">
              {successDonar ? (
                <p className="DonarDireccionEstado LetraVerde">{successDonar}</p>
              ) : errorDonar ? (
                <p className="DonarDireccionEstado LetraRoja">{errorDonar}</p>
              ) : null}
            </div>
          </div>

          <p>Tu aporte, sin importar el monto, es fundamental para mantener HACONTI activo y disponible para todos.
            ¡Agradecemos enormemente tu colaboración!
          </p>

        </div>


        <div className="BotonPlataforma">
          {primerIngreso ? (
            <Button variant="outline-success" onClick={() => {notificarPrimerIngresoRealizado();}}>Ir al menú principal</Button>
          ) : ( 
            <Link key="plataforma" to="/">
              <Button variant="outline-success">Volver al menú principal</Button>
            </Link>
          )}
        </div>

      </div>

      <div className="DonarFooter">
        <Footer/>
      </div>
  </div>
  );

}

export default Donar;