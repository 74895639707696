import '../styles/CardNivel.css';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';



function CardNivel(props) {

  const { nivel } = props;

  return(
    <div className={`card ${nivel.completo ? 'nivelCompleto' : 'nivelIncompleto'}`}>
      <Link to={`nivel/${nivel.id}`} className="btn">
        <div className="card-body">
          <h3 className="card-title"><b>{nivel.nombre}</b></h3>

            <img
              src={
                nivel.categoria === "1"
                  ? '/blue-book.png'
                  : nivel.categoria === "2"
                  ? '/blue-lock.png'
                  : '/blue-locks.png'
              }
              alt="Icono Nivel"
              className="IconoCategoria"
            />


          <div className="card-text">
            <span
              className={`dificultad ${nivel.dificultad === "Muy fácil" ? 'muy-facil' : nivel.dificultad === "Fácil" ? 'facil' : nivel.dificultad === "Normal" ? 'normal' : nivel.dificultad === "Difícil" ? 'dificil' : 'muy-dificil'}`}
            >
              <p><b>{nivel.dificultad}</b></p>
            </span>
          </div>

          <div className="card-text">
            <b>Premio:</b> {nivel.puntos.completo} puntos
          </div>
          <br/>


          {nivel.completo ? (
            <p className="card-text"><b className="LetraVerde">Resuelto</b></p>
          ) : (
            <p className="card-text"><b className="LetraRoja">No resuelto</b></p>
          )}


        </div>
      </Link>
    </div>
  );

}

export default CardNivel;