import { ethers } from 'ethers'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import { API_URL } from '../config';


function NivelPesca(props) {

  const { notificarIniciarSesion } = props;

  const [addressContratoJugador, setAddressContratoJugador] = useState()
  const [errorPesca, setErrorPesca] = useState()
  const [successPesca, setSuccessPesca] = useState()
  const [infoPesca, setInfoPesca] = useState()


  async function enviarAddress() {
    try {

      setInfoPesca("Por favor, esperá un momento mientras Pablo llama a tu contrato...");

      const response = await fetch(`${API_URL}/pesca`, {
        method: 'POST', 
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ addressContratoJugador }),
      });

      if(response.ok) {
        const jsonResponse = await response.json();
        setErrorPesca(null);
        setInfoPesca(null);
        setSuccessPesca(jsonResponse.mensaje);
        console.log("Mensaje: " + jsonResponse.mensaje);

      } else if (response.status === 401) {
        console.error("Error: Aún no has iniciado sesión");
        localStorage.removeItem("tokenAddress");
        localStorage.removeItem("tokenExpiration");
        notificarIniciarSesion();

      } else if (response.status === 429) {
        const textResponse = await response.text()
        setSuccessPesca(null);
        setInfoPesca(null);
        setErrorPesca(textResponse);
        console.error("Error: " + textResponse);
      
      } else {
        const jsonResponse = await response.json();
        setSuccessPesca(null);
        setInfoPesca(null);
        setErrorPesca(jsonResponse.error);
        console.error("Error: " + jsonResponse.error);
      }

    } catch (error) {
      console.error(error);
    }

  }


  return (
    <div className={`NivelPesca ${infoPesca ? "" : successPesca ? "Success" : errorPesca ? "Error" : ""}`}>

      <div className="PescaInputYBoton">
        <br/>
        <input className="PescaInput" onChange={e => setAddressContratoJugador(e.target.value)} placeholder="Ingresá la dirección del contrato que implementa obtenerEther()" />
        <br/>
        <Button onClick={enviarAddress} variant="outline-success">Enviar dirección a Pablo</Button>
      </div>

      <div className="PescaEstadoContainer">
        {infoPesca ? (
          <p className="PescaEstado LetraGris">{infoPesca}</p>
        ) : successPesca ? (
          <p className="PescaEstado LetraVerde">{successPesca}</p>
        ) : errorPesca ? (
          <p className="PescaEstado LetraRoja">{errorPesca}</p>
        ) : null}
      </div>

    </div>
  );

}

export default NivelPesca;