import '../styles/EditarPerfil.css';
import '@fortawesome/fontawesome-free/css/all.css';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import React, { useState, useEffect } from "react";
import ReactFlagsSelect from "react-flags-select";
import { FlagIcon } from "react-flag-kit";
import emailIcon from '../icons/ic-email.svg';
import githubIcon from '../icons/ic-github.svg';
import linkedinIcon from '../icons/ic-linkedin.svg';
import twitterIcon from '../icons/ic-twitter.svg';
import sitioWebIcon from '../icons/ic-link.svg';
import Footer from './Footer.js';

function EditarPerfil(props) {

  const { contratoPlataforma, web3, chainId, addressJugador, niveles, datosJugador, puntosTotales, notificarActualizarInfoJuego, notificarIniciarSesion } = props;

  const [success, setSuccess] = useState();
  const [info, setInfo] = useState();
  const [error, setError] = useState();
  const [codigoPais, setCodigoPais] = useState("");
  const [nick, setNick] = useState("");
  const [email, setEmail] = useState("");
  const [cuentaGithub, setCuentaGithub] = useState("");
  const [cuentaLinkedIn, setCuentaLinkedIn] = useState("");
  const [cuentaTwitter, setCuentaTwitter] = useState("");
  const [sitioWeb, setSitioWeb] = useState("");
  const [descripcion, setDescripcion] = useState("");

  const maxLength = 500;

  const puntosEditarNick = 200;
  const puntosEditarCodigoPais = 500;
  const puntosEditarEmail = 1500;
  const puntosEditarCuentaGithub = 1500;
  const puntosEditarCuentaLinkedIn = 1500;
  const puntosEditarCuentaTwitter = 1500;
  const puntosEditarSitioWeb = 1500;
  const puntosEditarDescripcion = 1000;


  const handleChange = (code) => {
    setCodigoPais(code);
  };


  const handleSocialMediaInput = (value, prefix, setter) => {
    // Verifica si el valor ingresado es una URL válida
    const isURL = new RegExp(`^(https?:\/\/(?:www\.)?${prefix}[a-zA-Z0-9_-]+)\/?$`, "i").test(value);
    if (isURL) {
      setter(value.replace(/\/$/, '')); // Elimina el slash al final si existe
    } else {
      const username = value.trim();
      const isValidUsername = /^[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*$/.test(username);
      if (isValidUsername) {
        setter(`https://${prefix}${username}`);
      } else {
        setter("");
      }
    }
  };


  const handleDescripcionChange = (event) => {
    const inputValue = event.target.value;
    const inputLength = inputValue.length;
    if (inputLength <= maxLength) {
      setDescripcion(inputValue);
    }
  };



  async function modificarDatosJugador() {
    try {

      setSuccess("");
      setError("");
      setInfo("Por favor, esperá un momento mientras se actualizan tus datos en la blockchain...");

      // Extraer nombres de usuario de las URLs
      const usuarioGithub = cuentaGithub.match(/github.com\/(.+)/)?.[1] || '';
      const usuarioLinkedIn = cuentaLinkedIn.match(/linkedin.com\/in\/(.+)/)?.[1] || '';
      const usuarioTwitter = cuentaTwitter.match(/twitter.com\/(.+)/)?.[1] || '';

      // Llamar a contratoPlataforma.modificarDatosJugador con los nombres de usuario
      let tx = await contratoPlataforma.modificarDatosJugador(
        nick,
        codigoPais,
        email,
        usuarioGithub,
        usuarioLinkedIn,
        usuarioTwitter,
        sitioWeb,
        descripcion
      );
  
      await tx.wait();

      actualizarEstadoConDatosDelJugador(usuarioGithub, usuarioLinkedIn, usuarioTwitter);

      setError("")
      setInfo("")
      setSuccess("¡Tus datos han sido actualizados en la blockchain satisfactoriamente!");

    } catch (error) {
      setInfo("");
      setSuccess("");
      console.error(error);
      const genericErrorMessage = "Error: VM Exception while processing transaction: reverted with reason string '";
      const endDelimiter = "'";
      if (error.message.includes(genericErrorMessage)) {
        const startIndex = error.message.indexOf(genericErrorMessage) + genericErrorMessage.length;
        const endIndex = error.message.indexOf(endDelimiter, startIndex);
        if (endIndex !== -1) {
          const revertMessage = error.message.substring(startIndex, endIndex);
          console.log("Mensaje del revert:", revertMessage);
          setError("Error: " + revertMessage);
        } else {
          console.log("No se pudo obtener el mensaje del revert.");
        }
      } else if (error.code === 'ACTION_REJECTED') {
        console.error("Error: Rechazaste la transacción");
        setError("Error: Rechazaste la transacción");
      } else {
        console.error("Error al procesar la transacción: ", error);
        setError("Error al procesar la transacción");
      }
    }

    return;
  }
  





  const copiarAlPortapapeles = () => {
    const textoACopiar = `${window.location.origin}/jugador/${addressJugador}`;
    navigator.clipboard.writeText(textoACopiar)
      .then(() => {
        setInfo('');
        setError('');
        setSuccess('¡La URL de tu perfil fue copiada al portapapeles!');
        //alert('¡URL copiada al portapapeles!');
      })
      .catch((error) => {
        console.error('Error al copiar al portapapeles: ', error);
        setSuccess('')
        setInfo('')
        setError('Error al copiar al portapapeles');
      });
  };



  function actualizarEstadoConDatosDelJugador(usuarioGithub, usuarioLinkedIn, usuarioTwitter) {
    datosJugador.codigoPais = codigoPais;
    datosJugador.nick = nick;
    datosJugador.email = email;
    datosJugador.cuentaGithub = usuarioGithub;
    datosJugador.cuentaLinkedIn = usuarioLinkedIn;
    datosJugador.cuentaTwitter = usuarioTwitter;
    datosJugador.sitioWeb = sitioWeb;
    datosJugador.descripcion = descripcion;
  }



  function establecerDatosDelJugador() {
    if(datosJugador !== undefined && datosJugador !== null) {
      setCodigoPais(datosJugador.codigoPais);
      setNick(datosJugador.nick);
      setEmail(datosJugador.email);
      setCuentaGithub("https://github.com/" + datosJugador.cuentaGithub);
      setCuentaLinkedIn("https://linkedin.com/in/" + datosJugador.cuentaLinkedIn);
      setCuentaTwitter("https://twitter.com/" + datosJugador.cuentaTwitter);
      setSitioWeb(datosJugador.sitioWeb);
      setDescripcion(datosJugador.descripcion);
    }
  }





  useEffect(() => {
    //obtenerDatosDelJugador();
    establecerDatosDelJugador();

  }, [datosJugador]);



  useEffect(() => {
    // Eliminar el mensaje de error cuando cambia algo en el estado
    setError("");
    setInfo("");
    setSuccess("");

  }, [addressJugador, codigoPais, nick, email, cuentaGithub, cuentaLinkedIn, cuentaTwitter, sitioWeb, descripcion]);



  useEffect(() => {
    const textarea = document.getElementById('descripcion');
    textarea.addEventListener('input', function() {
      const maxLength = 500;
      const length = this.value.length;
  
      if (length >= maxLength) {
        this.style.setProperty('--border-color', 'var(--max-color)');
        this.style.setProperty('--shadow-color', 'var(--max-color)');
      } else if (length >= maxLength - 50) {
        this.style.setProperty('--border-color', 'var(--near-max-color)');
        this.style.setProperty('--shadow-color', 'var(--near-max-color)');
      } else {
        this.style.setProperty('--border-color', 'var(--char-color)');
        this.style.setProperty('--shadow-color', 'var(--char-color)');
      }
    });
  }, []);



  return (
    <div className="EditarPerfil">

      <header className="Header">
        <h1 className="glitchText" datatext={"Editar perfil"}></h1>
      </header>

      <div className="BodyEditarPerfil">

        <div className="EditarPerfilContainer">

          {/* Campo de entrada para el nick */}
          <div className="form-group">
            {/* Expresión condicional para mostrar el mensaje y cambiar el color del label */}
            <label htmlFor="nick" className={puntosTotales !== undefined && puntosTotales < puntosEditarNick ? "label-disabled" : ""}>
              <strong>Nick</strong>&nbsp;&nbsp;
              {puntosTotales !== undefined && puntosTotales >= puntosEditarNick && (
                <div className="info-icon-container">
                  <i className="fas fa-info-circle icono-info"></i>
                  <span className="info-message">El nick debe tener entre 4 y 15 caracteres y estar compuesto únicamente por caracteres alfanuméricos, guión medio y guión bajo.</span>
                </div>
              )}
            </label>
            <input 
              id="nick"
              type="text"
              placeholder="Elegí un nick" 
              value={nick}
              onChange={(e) => setNick(e.target.value)}
              disabled={puntosTotales !== undefined && puntosTotales < puntosEditarNick}
              className={puntosTotales !== undefined && puntosTotales < puntosEditarNick ? "disabled-input" : ""}
            />
            {/* Expresión condicional para mostrar el mensaje */}
            {puntosTotales !== undefined && puntosTotales < puntosEditarNick && (
              <p className="ErrorPuntosInsuficientes">Necesitás {puntosEditarNick} puntos para editar el nick. Te faltan {puntosEditarNick - puntosTotales} puntos más.</p>
            )}
          </div>


          {/* Selector de país */}
          <div className="form-group">
            <label htmlFor="country" className={puntosTotales !== undefined && puntosTotales < puntosEditarCodigoPais ? "label-disabled" : ""}><strong>País</strong></label>
            <ReactFlagsSelect
              selected={codigoPais}
              onSelect={handleChange}
              placeholder="Seleccioná tu país"
              searchable
              searchPlaceholder="Buscá tu país"
              className={puntosTotales !== undefined && puntosTotales < puntosEditarCodigoPais ? "menu-flags disabled-input" : "menu-flags"}
              disabled={puntosTotales !== undefined && puntosTotales < puntosEditarCodigoPais}
            />
            {/* Expresión condicional para mostrar el mensaje */}
            {puntosTotales !== undefined && puntosTotales < puntosEditarCodigoPais && (
              <p className="ErrorPuntosInsuficientes">Necesitás {puntosEditarCodigoPais} puntos para editar el país. Te faltan {puntosEditarCodigoPais - puntosTotales} puntos más.</p>
            )}
          </div>

          
          {/* Campo de entrada para la descripción */}
          <div className="form-group">
            {/* Expresión condicional para cambiar el color del label */}
            <label htmlFor="descripcion" className={puntosTotales !== undefined && puntosTotales < puntosEditarDescripcion ? "label-disabled" : ""}><strong>Descripción</strong></label>
            <div className="descripcion-container">
              <textarea 
                id="descripcion"
                placeholder="¡Contanos algo sobre vos!"
                value={descripcion}
                onChange={handleDescripcionChange}
                disabled={puntosTotales !== undefined && puntosTotales < puntosEditarDescripcion}
                className={puntosTotales !== undefined && puntosTotales < puntosEditarDescripcion ? "disabled-input" : ""}
              />
              {/* Expresión condicional para mostrar el mensaje */}
              {puntosTotales !== undefined && puntosTotales < puntosEditarDescripcion && (
                <p className="ErrorPuntosInsuficientes">Necesitás {puntosEditarDescripcion} puntos para editar la descripción. Te faltan {puntosEditarDescripcion - puntosTotales} puntos más.</p>
              )}
              {puntosTotales !== undefined && puntosTotales >= puntosEditarDescripcion && (
                <span className={`descripcion-characters ${descripcion.length === maxLength ? 'max-length-reached' : (descripcion.length >= 450 && descripcion.length < 500 ? 'near-max-length' : '')}`}>
                  {descripcion.length}/{maxLength}
                </span>
              )}
            </div>
          </div>


          {/* Campo de entrada para el correo electrónico */}
          <div className="form-group social-media-input">
            {/* Expresión condicional para cambiar el color del label */}
            <label htmlFor="email" className={puntosTotales !== undefined && puntosTotales < puntosEditarEmail ? "label-disabled" : ""}><strong>Email</strong></label>
            <div className="social-media-input-container">
              <img src={emailIcon} alt="Email" />
              <input 
                id="email"
                type="text"
                placeholder="ejemplo@dominio.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={puntosTotales !== undefined && puntosTotales < puntosEditarEmail}
                className={puntosTotales !== undefined && puntosTotales < puntosEditarEmail ? "disabled-input" : ""}
              />
            </div>
            {/* Expresión condicional para mostrar el mensaje */}
            {puntosTotales !== undefined && puntosTotales < puntosEditarEmail && (
              <p className="ErrorPuntosInsuficientes">Necesitás {puntosEditarEmail} puntos para editar el email. Te faltan {puntosEditarEmail - puntosTotales} puntos más.</p>
            )}
          </div>


          {/* Campo de entrada para la cuenta de GitHub */}
          <div className="form-group social-media-input">
            {/* Expresión condicional para cambiar el color del label */}
            <label htmlFor="github" className={puntosTotales !== undefined && puntosTotales < puntosEditarCuentaGithub ? "label-disabled" : ""}><strong>GitHub</strong></label>
            <div className="social-media-input-container">
              <img src={githubIcon} alt="GitHub" />
              <input 
                id="github"
                type="text" 
                placeholder="https://github.com/<usuario>"
                value={cuentaGithub}
                onChange={(e) => handleSocialMediaInput(e.target.value, "github.com/", setCuentaGithub)}
                disabled={puntosTotales !== undefined && puntosTotales < puntosEditarCuentaGithub}
                className={puntosTotales !== undefined && puntosTotales < puntosEditarCuentaGithub ? "disabled-input" : ""}
              />
            </div>
            {/* Expresión condicional para mostrar el mensaje */}
            {puntosTotales !== undefined && puntosTotales < puntosEditarCuentaGithub && (
              <p className="ErrorPuntosInsuficientes">Necesitás {puntosEditarCuentaGithub} puntos para editar la cuenta de Github. Te faltan {puntosEditarCuentaGithub - puntosTotales} puntos más.</p>
            )}
          </div>


          {/* Campo de entrada para la cuenta de LinkedIn */}
          <div className="form-group social-media-input">
            {/* Expresión condicional para cambiar el color del label */}
            <label htmlFor="linkedin" className={puntosTotales !== undefined && puntosTotales < puntosEditarCuentaLinkedIn ? "label-disabled" : ""}><strong>LinkedIn</strong></label>
            <div className="social-media-input-container">
              <img src={linkedinIcon} alt="LinkedIn" />
              <input 
                id="linkedin"
                type="text" 
                placeholder="https://linkedin.com/in/<usuario>"
                value={cuentaLinkedIn}
                onChange={(e) => handleSocialMediaInput(e.target.value, "linkedin.com/in/", setCuentaLinkedIn)}
                disabled={puntosTotales !== undefined && puntosTotales < puntosEditarCuentaLinkedIn}
                className={puntosTotales !== undefined && puntosTotales < puntosEditarCuentaLinkedIn ? "disabled-input" : ""}
              />
            </div>
            {/* Expresión condicional para mostrar el mensaje */}
            {puntosTotales !== undefined && puntosTotales < puntosEditarCuentaLinkedIn && (
              <p className="ErrorPuntosInsuficientes">Necesitás {puntosEditarCuentaLinkedIn} puntos para editar la cuenta de LinkedIn. Te faltan {puntosEditarCuentaLinkedIn - puntosTotales} puntos más.</p>
            )}
          </div>


          {/* Campo de entrada para la cuenta de Twitter */}
          <div className="form-group social-media-input">
            {/* Expresión condicional para cambiar el color del label */}
            <label htmlFor="twitter" className={puntosTotales !== undefined && puntosTotales < puntosEditarCuentaTwitter ? "label-disabled" : ""}><strong>Twitter</strong></label>
            <div className="social-media-input-container">
              <img src={twitterIcon} alt="Twitter" />
              <input 
                id="twitter"
                type="text" 
                placeholder="https://twitter.com/<usuario>"
                value={cuentaTwitter}
                onChange={(e) => handleSocialMediaInput(e.target.value, "twitter.com/", setCuentaTwitter)}
                disabled={puntosTotales !== undefined && puntosTotales < puntosEditarCuentaTwitter}
                className={puntosTotales !== undefined && puntosTotales < puntosEditarCuentaTwitter ? "disabled-input" : ""}
              />
            </div>
            {/* Expresión condicional para mostrar el mensaje */}
            {puntosTotales !== undefined && puntosTotales < puntosEditarCuentaTwitter && (
              <p className="ErrorPuntosInsuficientes">Necesitás {puntosEditarCuentaTwitter} puntos para editar la cuenta de Twitter. Te faltan {puntosEditarCuentaTwitter - puntosTotales} puntos más.</p>
            )}
          </div>


          {/* Campo de entrada para el sitio web */}
          <div className="form-group social-media-input">
            {/* Expresión condicional para cambiar el color del label */}
            <label htmlFor="sitioWeb" className={puntosTotales !== undefined && puntosTotales < puntosEditarSitioWeb ? "label-disabled" : ""}><strong>Sitio web</strong></label>
            <div className="social-media-input-container">
              <img src={sitioWebIcon} alt="SitioWeb" />
              <input 
                id="sitioWeb"
                type="text" 
                placeholder="https://dominio.com"
                value={sitioWeb}
                onChange={(e) => setSitioWeb(e.target.value)}
                disabled={puntosTotales !== undefined && puntosTotales < puntosEditarSitioWeb}
                className={puntosTotales !== undefined && puntosTotales < puntosEditarSitioWeb ? "disabled-input" : ""}
              />
            </div>
            {/* Expresión condicional para mostrar el mensaje */}
            {puntosTotales !== undefined && puntosTotales < puntosEditarSitioWeb && (
              <p className="ErrorPuntosInsuficientes">Necesitás {puntosEditarSitioWeb} puntos para editar el sitio web. Te faltan {puntosEditarSitioWeb - puntosTotales} puntos más.</p>
            )}
          </div>



        </div>


        <div className="EstadoEditarPerfilContainer">
          {info ? (
            <p className="EstadoEditarPerfil LetraGris">{info}</p>
          ) : success ? (
            <p className="EstadoEditarPerfil LetraVerde">{success}</p>
          ) : error ? (
            <p className="EstadoEditarPerfil LetraRoja">{error}</p>
          ) : null}
        </div>


        <div className="BotonPlataforma">

          { puntosTotales && puntosTotales > 0 ? (
            <>
              <Button onClick={modificarDatosJugador} variant="outline-success">Guardar cambios</Button>
              
              <Link key="plataforma" to={`/jugador/${addressJugador}`}>
                <Button variant="outline-success">Ver mi perfil</Button>
              </Link>

              <Button variant="outline-success" onClick={copiarAlPortapapeles}>Copiar URL de mi perfil</Button>
            </>
          ) : null }

          <Link key="plataforma" to="/">
            <Button variant="outline-success">Volver al menú principal</Button>
          </Link>
        </div>

      </div>

      <div className="AyudaFooter">
        <Footer/>
      </div>

    </div>
    
  );
};

export default EditarPerfil;