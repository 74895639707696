import '../styles/Ayuda.css';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Footer from './Footer.js';


function Ayuda(props) {

  const { contratoPlataforma, web3, chainId, addressJugador, niveles, puntosTotales, notificarIniciarSesion, primerIngreso, notificarPrimerIngresoRealizado } = props;


  const handleClick = () => {
    // Redireccionar a la página principal
    //window.location.href = '/';
    
    // Desplazar al principio de la página después de la redirección
    //window.scrollTo(0, 0);

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant' // Esto hará que el desplazamiento sea instantáneo
    });
  };


  return (
    <div className="Ayuda">

      {primerIngreso ? (
        <header className="Header">
          <h1 className="glitchText" datatext={"HACONTI"}></h1>
          <h3 className="LetraVerde"><b>Hackeá Al Contrato No Tan Inteligente</b></h3>
        </header>
      ) : ( 
        <header className="Header">
          <h1 className="glitchText" datatext={"Ayuda"}></h1>
        </header>
      )}

      <div className="AyudaContenedor">
        <h4 className="AyudaTitulo">Introducción y requisitos para jugar</h4>

        <p>¡Bienvenido a HACONTI! En la plataforma encontrarás una serie de desafios de ciberseguridad en Smart Contracts 
        desarrollados en el lenguaje Solidity. Un aspecto clave de la plataforma es que en los primeros niveles aprenderás 
        lo básico de Solidity. Esto te habilitará a resolver una diversa cantidad de desafíos sin tener que haber desarrollado 
        antes en el lenguaje.</p>
        
        <p>Sin embargo, es necesario contar con conocimientos básicos sobre Ethereum y su funcionamiento. 
        Afortunadamente, en la web oficial de Ethereum hay documentación introductoria para iniciarse rápidamente:</p>

        <a href="https://ethereum.org/es/developers/docs/intro-to-ethereum">Introducción a Ethereum</a><br/><br/>

        <p>Si bien con la lectura anterior puede ser suficiente, es recomendable profundizar en los distintos "Temas fundamentales":</p>
        
        <a href="https://ethereum.org/es/developers/docs#foundational-topics">Ethereum: Temas fundamentales</a><br/><br/>

        <p>¡Con los conocimientos anteriores estás en condiciones de empezar a jugar! Te recomendamos iniciar con el nivel "Bienvenida"
        y continuar con los tres niveles introductorios que le siguen. En ellos obtendrás los fundamentos de Solidity, aprenderás a 
        interactuar con los contratos de cada nivel, y a preparar tu entorno y herramientas para resolver los desafíos.</p>

        <br/><br/>



        <h4 className="AyudaTitulo">Categorías de los niveles</h4>

        <p>Los niveles de la plataforma se agrupan en tres categorías, dependiendo del tipo de nivel a resolver. 
        Cada categoría se representa con un símbolo distinto:</p>

        <p><img src="/blue-book.png" alt="MetaMask Logo" className="IconoCategoria"/><strong className="LetraAzul"> Introductoria: </strong>
        Son niveles diseñados para que te familiarices con el lenguaje Solidity, y para orientarte a preparar tu entorno y herramientas 
        para resolver el resto de niveles, y desplegar contratos. </p>
        <p><img src="/blue-lock.png" alt="MetaMask Logo" className="IconoCategoria"/><strong className="LetraAzul"> Vulnerabilidades simples: </strong> 
        Cada nivel es un contrato con alguna vulnerabilidad concreta que debe ser explotada. Al abordar todos los niveles de esta 
        categoría, se obtendrá un profundo conocimiento de las distintas vulnerabilidades existentes en el ecosistema de Ethereum. </p>
        <p><img src="/blue-locks.png" alt="MetaMask Logo" className="IconoCategoria"/><strong className="LetraAzul"> Vulnerabilidades 
        encadenadas y/o complejas:</strong> Son niveles diseñados para poner a prueba tus habilidades adquiridas al resolver niveles previos 
        en la plataforma. Cada nivel es de dificultad máxima y cuenta con una serie de vulnerabilidades que deben ser explotadas en 
        cadena para su resolución. </p>


        <br/><br/>



        <h4 className="AyudaTitulo">Sistema de puntos y dificultades</h4>

        <p>HACONTI incorpora un sistema de puntos novedoso. Los puntos que sumás por completar niveles no solo te sirven para 
          posicionarte en el ranking, sino que también te sirven para desbloquear información útil para resolver los desafíos. 
          Por cada nivel podés desbloquear hints, hints adicionales e información sobre SWC y SCSVS (términos explicados en la 
          próxima sección).</p>

        <p>Los desafíos se categorizan en cinco niveles de dificultad, y según cada nivel, otorgan diferentes cantidades de puntos. 
        Las dificultades y las respectivas puntuaciones asociadas son las siguientes: </p>

        <ul>
          <li><strong className="LetraVerde">Muy fácil:</strong> 50 puntos</li>
          <li><strong className="LetraVerde">Fácil:</strong> 100 puntos</li>
          <li><strong className="LetraAmarilla">Normal:</strong> 150 puntos</li>
          <li><strong className="LetraRoja">Difícil:</strong> 200 puntos</li>
          <li><strong className="LetraRoja">Muy difícil:</strong> 300 puntos</li>
        </ul>

        <p><b>Nota:</b> Te recomendamos no resolver los desafíos con ayuda de writeups de Internet, ya que pierde la escencia de HACONTI, que
          consiste en ir completando niveles y sumando puntos para desbloquear información que te ayude a resolver y destrabar todos
          los niveles restantes. Resolver los desafíos de HACONTI con writeups de Internet, es como usar trucos en los videojuegos.
          Además, sumando las hints, hints adicionales e información sobre SWC y SCSVS tenés información de sobra para resolverlos por
          tu cuenta. Por si fuera poco, el nombre de cada nivel también te da un indicio sobre la vulnerabilidad presente en el contrato
          del mismo. Una vez resuelto cada nivel, sí puede ser valioso consultar writeups para ver como otros jugadores lo hicieron.
        </p>

        <br/><br/>



        <h4 className="AyudaTitulo">SWC y SCSVS</h4>
        
        <p><strong className="LetraAzul">SWC:</strong> El <i>Smart Contract Weakness Classification</i> (<a href="https://swcregistry.io/">SWC</a>) es un esquema de clasificación de vulnerabilidades 
        específico para contratos inteligentes. Está dirigido a desarrolladores, proveedores de herramientas y profesionales 
        de seguridad, y se presentó como parte del <i>EIP-1470</i> (Ethereum Improvement Proposal), un proceso utilizado en la 
        comunidad de Ethereum para proponer y discutir mejoras en la red de Ethereum.</p>

        <p><strong className="LetraAzul">SCSVS:</strong> El <i>Smart Contract Security Verification Standard</i> (<a href="https://github.com/ComposableSecurity/SCSVS">SCSVS</a>)
        es una lista de verificación gratuita diseñada para estandarizar la seguridad de los contratos inteligentes, dirigida a 
        desarrolladores, arquitectos, revisores de seguridad y proveedores.</p>

        <p>Por cada nivel de la plataforma, se pueden desbloquear los items de la lista de verificación SCSVS que se incumplen, 
        dando lugar a la vulnerabilidad abordada en el nivel. También se puede desbloquear el SWC asociado a la vulnerabilidad 
        del nivel. Sin embargo, como el esquema SWC no se encuentra en mantenimiento, hay vulnerabilidades abordadas que no 
        tienen un SWC específico.</p>

        <br/><br/>



        <h4 className="AyudaTitulo">Información asociada a los niveles</h4>
        
        <p>Una característica clave de la plataforma es que cada nivel aporta una gran cantidad de información relacionada 
        con el desafío y la vulnerabilidad a explotar.</p>

        <p>Inicialmente se muestran los objetivos de superación junto con una guía (en los niveles introductorios), 
        o una descripción (en el resto de niveles que incluyen vulnerabilidades). También existen hints, hints adicionales 
        e información sobre SWC y SCSVS que se desbloquean sumando una distinta cantidad de puntos.</p>

        <p>Al resolver un nivel, se desbloquea una abundante cantidad de información que puede incluir:</p>

        <ul>
          <li><strong className="LetraAzul">Información adicional:</strong> Detalles técnicos adicionales sobre la resolución del nivel.</li>
          <li><strong className="LetraAzul">Código corregido:</strong> El código fuente corregido del nivel
        empleando distintas contramedidas.</li>
          <li><strong className="LetraAzul">Casos reales:</strong> Ejemplos del mundo real en el que la explotación de una vulnerabilidad similar a la del 
        nivel resultó en el robo de millones de dólares de un contrato vulnerable.</li>
          <li><strong className="LetraAzul">Artículos adicionales:</strong> Articulos de utilidad para entender más en detalle sobre la vulnerabilidad abordad en el nivel.</li>
          <li><strong className="LetraAzul">Herramientas útiles:</strong> Herramientas auxiliares que sirven de ayuda para explotar vulnerabilidades similares a la del nivel.</li>
        </ul>
          

        <p>Asimismo, luego de resolver un nivel se desbloquean las hints, hints adicionales e información sobre SWC y SVSVS asociada, 
        por más que no cuentes con la cantidad de puntos de desbloqueo suficientes.</p>

        <br/><br/>




        <h4 className="AyudaTitulo">Componentes de la plataforma</h4>

        <p>HACONTI está compuesto por un front-end, una API y contratos inteligentes que residen en la red de pruebas de Sepolia:</p>

        <ul>
          <li className="MarginBottom15"><strong className="LetraAzul">Front-end:</strong> Es la vista de la aplicación con la que interactuás desde el navegador.
          Actúa como intermediario entre vos (jugador), la API y los contratos inteligentes de la plataforma.</li>
          <li className="MarginBottom15"><strong className="LetraAzul">API:</strong> Almacena la información desbloqueable, y consulta al contrato principal de la plataforma si
          cumplís con los requisitos necesarios para que se te muestre la información (es decir, si tenés los puntos suficientes o si completaste los niveles en cuestión).</li>
          <li><strong className="LetraAzul">Contratos inteligentes:</strong> Son el corazón de la plataforma y consisten en distintos contratos, que residen en la red de
          pruebas de Sepolia, y podemos dividir en las siguientes categorías:
            <ul>
              <li className="MarginTop15 MarginBottom15"><strong className="LetraAzul">Contrato principal de la plataforma: </strong> 
              Almacena en la Blockchain las direcciones de los contratos "Factory" de cada nivel, asi como las direcciones de los jugadores
              y los puntos sumados por cada uno. Se comunica con los contratos "Factory" de los niveles para crear y validar las instancias
              de los niveles. La dirección mostrada como "Plataforma" al abrir la ventana de desarrollo con F12, hace referencia a la dirección del contrato
              principal de la plataforma.
              </li>
              <li className="MarginBottom15"><strong className="LetraAzul">Contratos "Factory" de los niveles: </strong>
              Su función principal es desplegar dinámicamente distintos contratos de un mismo nivel para distintos jugadores. Cada contrato 
              de un mismo nivel es una instancia del nivel, asociada a un jugador en particular. Cada jugador interactúa con su propia 
              instancia del nivel que está resolviendo, lo que permite un aislamiento entre los contratos con los que interactúan los 
              distintos jugadores. Los contratos "Factory" proveen métodos para crear y validar las instancias de los niveles. La dirección mostrada como "Nivel" al abrir 
              la ventana de desarrollo con F12 (dentro de algún nivel en particular), hace referencia al contrato "Factory" de ese nivel.
              </li>
              <li className="MarginBottom15"><strong className="LetraAzul">Contratos de las instancias de niveles: </strong>
              En el momento en el que clickeas en el botón "Crear Instancia" dentro de un nivel determinado, estás desplegando un nuevo 
              contrato que representa una instancia del nivel que estás resolviendo. Para resolver los niveles, debés interactuar directamente
              con estos contratos. La dirección mostrada como "Instancia" al abrir la ventana de desarrollo con F12 (dentro de algún nivel en particular),
              hace referencia al contrato de la instancia que creaste para ese nivel.
              </li>
            </ul>
          </li>
        </ul>
        
        <br/><br/>



        <h4 className="AyudaTitulo">Solución de problemas (troubleshooting)</h4>

        <ul>
          <li><strong className="LetraRoja">Secciones del sitio no cargan:</strong></li> 
          
          <p>Si no te carga el ranking, o cualquier otra sección dentro del sitio, la solución más simple es volver a cargar la página con el navegador presionando F5. Muchas veces, las inconsistencias en los estados se resuelven
          de ésta manera. </p>

          <li><strong className="LetraRoja">Nonce too high/low:</strong></li> 

          <p> Se refiere a un problema relacionado con el nonce de tu cuenta de Ethereum. El nonce es un número único utilizado en cada 
          transacción para evitar duplicaciones y mantener el orden. Sin embargo, a veces puede desincronizarse, lo que significa que el 
          número proporcionado es demasiado alto o bajo en comparación con el esperado. Si al crear una nueva instancia o al efectuar una 
          transacción desde MetaMask recibimos el mensaje de error: <i>"Nonce too high"</i> o <i>"Nonce too low"</i>, significa que nos vemos afectados por 
          el problema.</p>
          
          <p>Para solucionarlo, abrimos la extensión MetaMask, clickeamos en los tres puntos que se encuentran en la esquina superior 
          derecha. Luego clickeamos en "Configuración", después en "Avanzado" y finalmente clickeamos en el botón "Borrar datos de la pestaña 
          de actividad". Ésto restablecerá el nonce de nuestra cuenta, evitando que MetaMask nos siga devolviendo el error.</p>


          <li><strong className="LetraRoja">Las transacciones nunca se confirman:</strong></li> 
          
          <p>Uno de los problemas más habituales es que haya congestionamiento en la red de pruebas de Sepolia. Esto ocurre cuando
          se encolan muchas transacciones de muchos usuarios, y quedan pendientes de confirmación. Lo anterior, conduce a que debamos esperar más tiempo
          hasta que se confirmen nuestras transacciones, o que debamos aumentar el precio abonado por unidad de gas para que los validadores
          de bloque prioricen nuestras transacciones. </p>
          
          <p>Si la congestión es muy alta, incluso se puede imposibilitar la interacción con los contratos
          de la plataforma. Una señal de congestión muy alta es recibir el error: <i>"Replacement Transaction Underpriced"</i> al realizar transacciones
          con MetaMask. Para sobreponernos al error, cuando queramos efectuar alguna transacción, antes de clickear el botón "Confirmar" de MetaMask, debemos
          clickear en el ícono del lápiz azul dentro del recuadro de "Estimated fee". Luego, clickeamos en "Avanzado", y allí podemos incrementar tanto la
          "Tarifa base máxima", como la "Tarifa de prioridad", hasta que dejemos de recibir el error. Tengamos en cuenta que cuando la congestión es muy alta,
          las tarifas de gas para que los validadores nos tomen la transacción pueden ser muy elevadas. </p>
          
          <p>Si deseamos iniciar transacciones desde un archivo de JavaScript
          con Hardhat (según lo explicado luego de resolver el nivel "Bienvenida"), podemos incrementar el valor de "gasPrice" en el archivo hardhat.config.js.
          Sin embargo, tengamos en cuenta que algunas veces, por más establezcamos un "gasPrice" muy alto, si la congestión es muy notable, la ejecución de los
          scripts no será satisfactoria, y tendremos que esperar a que la red se descongestione. Afortunadamente, luego de resolver el nivel "Desde Contrato", se
          explica como desplegar los contratos del nivel de manera local, para resolverlos localmente y sobreponerse a las congestiones en la red de pruebas. </p>
      
        </ul>
      </div>



      <div className="BotonPlataforma">
        {primerIngreso ? (
          <Button variant="outline-success" onClick={() => {notificarPrimerIngresoRealizado();}}>Ir al menú principal</Button>
        ) : ( 
          <Link key="plataforma" to="/">
            <Button variant="outline-success">Volver al menú principal</Button>
          </Link>
        )}
      </div>


      <div className="AyudaFooter">
        <Footer/>
      </div>
  </div>
  );

}

export default Ayuda;