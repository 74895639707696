import Accordion from 'react-bootstrap/Accordion';
import ReactMarkdown from 'react-markdown'
import hljs from 'highlight.js'
import hljsDefineSolidity from 'highlightjs-solidity';
import 'bootstrap/dist/css/bootstrap.min.css';


function NivelInfo(props) {

  const { infoNivel, itemsAccordionExpandidos, esInfoResuelto } = props;


  function obtenerCodigoResaltado(codigo, lenguaje) {
    hljsDefineSolidity(hljs);
    const codigoResaltado = hljs.highlight(codigo, { language: lenguaje }).value;
    return (
      <pre><code className='hljs' dangerouslySetInnerHTML={{ __html: codigoResaltado }} /></pre>
    );
  }


  function renderizarComponentes(textoCompleto) {
    // Expresión regular para encontrar bloques de código delimitados por ```lenguaje
    const regexCodigo = /```(\w+)\r?\n([\s\S]*?)\r?\n```/g;
    // Inicializamos un array para almacenar los componentes renderizados
    const componentes = [];
    // Inicializamos la variable lastIndex para llevar el seguimiento del último índice procesado

    // Iteramos sobre los bloques de código encontrados
    let match;
    let lastIndex = 0;
    while ((match = regexCodigo.exec(textoCompleto)) !== null) {
        // Extraemos el lenguaje y el fragmento de código del match
        const [_, lenguaje, fragmento] = match;

        // Extraemos el texto normal entre bloques de código
        const textoNormal = textoCompleto.substring(lastIndex, match.index);
        // Si hay texto normal entre bloques, lo renderizamos con ReactMarkdown
        if (textoNormal.trim() !== '') {
            componentes.push(
                <ReactMarkdown key={`texto_${lastIndex}`}>
                    {textoNormal.trim()}
                </ReactMarkdown>
            );
        }

        // Si el fragmento de código no está vacío, lo renderizamos dentro de un div
        if (fragmento.trim() !== '') {
            componentes.push(
                <div key={`codigo_${lastIndex}`}>
                    {obtenerCodigoResaltado(fragmento.trim(), lenguaje)}
                </div>
            );
        }

        // Actualizamos el valor de lastIndex para el próximo ciclo
        lastIndex = regexCodigo.lastIndex;
    }

    // Capturamos el texto final después del último bloque de código
    const textoFinal = textoCompleto.substring(lastIndex);
    // Si hay texto final, lo renderizamos con ReactMarkdown
    if (textoFinal.trim() !== '') {
        componentes.push(
            <ReactMarkdown key={`texto_${lastIndex}`}>
                {textoFinal.trim()}
            </ReactMarkdown>
        );
    }

    // Devolvemos el array con los componentes renderizados
    return componentes;
  }
  



  function renderizarAccordion() {

    const informacionSinObjetivos = { ...infoNivel };
    delete informacionSinObjetivos.Objetivos;

    return (
      <Accordion defaultActiveKey={itemsAccordionExpandidos} alwaysOpen className="AccordionInfo">
        {Object.keys(informacionSinObjetivos).map((key, index) => {
          const _informacion = informacionSinObjetivos[key];
          return (
            // Verifica si _informacion es una cadena vacía antes de renderizar
            _informacion !== "" ? (
              <Accordion.Item key={index} eventKey={index.toString()} className="ItemAccordeon">
                <Accordion.Header>
                <b className={(_informacion.includes('Necesitás') && _informacion.includes('puntos más para desbloquear')) ? 'LetraRoja' : (esInfoResuelto ? "LetraVerde" : "LetraGris")}>{key}</b>
                </Accordion.Header>
                <Accordion.Body>
                  {/* {renderizarComponentes(_informacion)} */}
                  {(_informacion.includes('Necesitás') && _informacion.includes('puntos más para desbloquear')) ? (
                    <div className="TextoError" key={index}>
                      {_informacion}
                    </div>
                  ) : (
                    renderizarComponentes(_informacion)
                  )}
                </Accordion.Body>
              </Accordion.Item>
            ) : null
          );
        })}
      </Accordion>
    );
  }



  return (
    <div className="NivelInfo">
      {infoNivel && itemsAccordionExpandidos && esInfoResuelto != null && (
        renderizarAccordion()
      )}
    </div>
  );

}

export default NivelInfo;