import '../styles/VentanaCambiarRed.css';
import { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CHAIN_ID as sepoliaChainId } from '../config';

//const sepoliaChainId = "31337";
//const sepoliaChainId = "11155111";


function VentanaCambiarRed(props) {

  const { chainId, setConfirmarCreacion = undefined } = props;


  // Cambiar la red a Sepolia
  async function cambiarRedASepolia() {
    const ventanaCambiarRed = document.querySelectorAll('.ventanaCambiarRedContainer');
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${Number(sepoliaChainId).toString(16)}` }], // Si está en la red equivocada, ofrecer la opción de cambiar a la red de Sepolia.
      });
      ventanaCambiarRed[0].style.display = 'none';
    } catch (switchError) {
      //  El código de error indica que la cadena no ha sido agregada a MetaMask.
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: [{ chainId: `0x${Number(sepoliaChainId).toString(16)}` }]
              },
            ],
          });
          ventanaCambiarRed[0].style.display = 'none';
        } catch (addError) {
          if (addError.code === 4001) {
            // El jugador rechazó la solicitud de cambio de red
            // elements[0].style.display = 'none';
          }
          console.error("No se puede añadir ni cambiar a la red seleccionada")
        }
      } else if (switchError.code === 4001) {
        // El jugador rechazó la solicitud de cambio de red
        // if (elements[0]) elements[0].style.display = 'none';
      }
    }
  }


  useEffect(() => {
    if(chainId != undefined) {
      // Si chainId es distinto de Sepolia:
      if(chainId != sepoliaChainId) {
        // Si la ventana de confirmar creación estaba abierta, primero la cerramos
        if(setConfirmarCreacion !== undefined) {
          setConfirmarCreacion(false);
        }
        // Mostramos la ventana de Cambiar red
        const ventanaCambiarRed = document.querySelectorAll('.ventanaCambiarRedContainer');
        if (ventanaCambiarRed[0]) ventanaCambiarRed[0].style.display = 'block';
      } else {
        // Si chainId es Sepolia, mantenemos la ventana oculta
        const ventanaCambiarRed = document.querySelectorAll('.ventanaCambiarRedContainer');
        if (ventanaCambiarRed[0]) ventanaCambiarRed[0].style.display = 'none';
      }
    }
  
  }, [chainId]);


  return (
    <div className="ventanaCambiarRedContainer">
      <div className="ventanaCambiarRed">
        <h4><b>Error</b>: ¡Estás conectado a la red incorrecta!</h4>
        <p>La plataforma opera sobre la red de pruebas <b>Sepolia</b>.
        Para resolver los desafíos es necesario que te conectes a la misma desde MetaMask.</p>
        <Button onClick={cambiarRedASepolia} variant="outline-success">Conectarme a Sepolia</Button>
      </div>
    </div>
  );

}

export default VentanaCambiarRed;